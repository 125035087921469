import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicAccordionCheck from "./BasicAccordionCheck";
import Cookies from "universal-cookie";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import BasicAccordionPdf from "./BasicAccordionPdf";
import { useEffect, useState } from "react";

interface SubCategoria {
  id: number;
  padre_id: number;
  label: string;
  titulo: string;
  tipo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  estado: string;
  delete: number;
  isChecked: boolean;
}

interface Categoria {
  id: number;
  padre_id: number;
  label: string;
  titulo: string;
  tipo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  estado: string;
  isChecked: boolean;
  subCategorias: SubCategoria[];
}


interface pdfData {
  file_id: any;
  file: string;
  name: string;
  estado: string;
}

interface CheckUlt {
  id: number;
  modelo_id: number;
  ultimo_id: number;
}

interface Props {
  titulo: string;
  contenido: string;
  model: string;
  categorias: Categoria[];
  isMobile: boolean;
  filesPdf: pdfData[];
  CheckUlt: CheckUlt;
}

export default function BasicAccordionDir(props: Props) {
  const { titulo, contenido, categorias, model, filesPdf, isMobile, CheckUlt } =
    props;

  const [openAccordion, setOpenAccordion] = useState(false);
  const [openInicio, setOpenInicio] = useState(false);

  useEffect(() => {
    // console.log(CheckUlt);
    if (CheckUlt) {
      categorias.map((categoria, key) => {
        if (CheckUlt.modelo_id === categoria.id) {
          setOpenAccordion(true);
        }

        // 
        categoria.subCategorias.map((subcategoria, key) => {
          if (subcategoria.id == CheckUlt.modelo_id) {

            setOpenAccordion(true);
          }


        });
      });
      

      setOpenInicio(true);
    }
  }, [categorias, CheckUlt.modelo_id]);

  const cookies = new Cookies();
  const user_id = cookies.get("user_id");

  const accordionData = [
    {
      titulo: titulo,
      contenido: contenido,
      categorias: categorias,
      model: model,
      filesPdf: filesPdf,
      isMobile: isMobile,
    },
  ];

  return (
    <div>
      {openInicio && (
        <>
          {accordionData.map((item, index) => (
            <Accordion
              key={index}
              defaultExpanded={openAccordion}
              style={{ backgroundColor: "#FEF5E7", fontSize: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography>
                  <strong>{item.titulo}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <span dangerouslySetInnerHTML={{ __html: item.contenido }} />
                </Typography>
                {item.categorias.map((categoria, index100) => (
                  // <div key={index100} >
                  <div key={index100}>
                    <BasicAccordionCheck
                      categoria={categoria}
                      user_id={user_id}
                      model="directorio"
                      CheckUlt={CheckUlt}
                    />
                  </div>
                  // </div>
                ))}

                {filesPdf.map((file, index5) => (
                  <div key={index5}>
                    <BasicAccordionPdf
                      titulo={file.name}
                      filePdf={file.file}
                    ></BasicAccordionPdf>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
}
