
import { useEffect, useState } from 'react'
import TopBar from 'elements/TopBar/TopBar';
import DateBar from 'elements/DateBar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';
import 'assets/css/main.css';

export default function AnoLiturgicoCategories(props:any) {

  const {
    inHome,
    fixedDate = null,
  } = props;

  const [loading, setLoading] = useState(false);

  const getDate = () => {

    return localStorage.getItem('date');
  }
  
  const [date, setDate] = useState(getDate());
  const [forceOpen, setForceOpen] = useState(getDate() === "null");

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');
  const modulo  = 'Año Litúrgico';

  useEffect(() => { props.setLoading(loading) }, [loading]);

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    axios.get(ProjectConfig.api_url, {
      params: {
        request: "calendario_ano",
        date: date,
        user_id: user_id,
        modulo: modulo
      },
    })
    .then((response) => {
      console.log("res",response.data);
      // setData(response.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.data);
    });
  }, [date]);
  /*=======================================
  =======================================*/

  return (
    <div id='AnoLiturgicoCategories' className='module_container'>
      
      {!inHome &&
        <div className="module_topBar">
          <TopBar title='Año Litúrgico'/>
          <DateBar onChange={setDate} forceOpen={forceOpen}/>
        </div>      
      }
      
      <div className='module_content'>
        <div className='module_wrapper'>
          <div className='CardContents' >
              <h2>LITURGIA DE LAS HORAS</h2>
          </div>

          <div id="AnoLiturgico_categories" className="flex_column">

              <div className='CardList'>
                <Link to='/ano-liturgico/oficio-lectura' className='icon' onClick={() => props.setLoading(true)}> 
                  <span className='CardListTitle'>Oficio de lectura</span>
                </Link>
              </div>

              <div className='CardList'>
                <Link to='/ano-liturgico/laudes' className='icon' onClick={() => props.setLoading(true)}> 
                  <span className='CardListTitle'>Laudes</span>
                </Link>
              </div>

              <div className='CardList'>
                <Link to='/ano-liturgico/intermedias' className='icon' onClick={() => props.setLoading(true)}>
                  <span className='CardListTitle'>Horas Intermedias</span>
                </Link>
              </div>
            
              <div className='CardList'>
                <Link to='/ano-liturgico/visperas' className='icon' onClick={() => props.setLoading(true)}>
                  <span className='CardListTitle'>Vísperas</span>
                </Link>
              </div>

              <div className='CardList'>
                <Link to='/ano-liturgico/completas' className='icon' onClick={() => props.setLoading(true)}>
                  <span className='CardListTitle'>Completas</span>
                </Link>
              </div>
          </div>
        </div>
        
        <div className='module_wrapper'>
          <div className='CardContents' >
              <h2>MISA DEL DIA</h2>
          </div>

          <div id="AnoLiturgico_categories" className="flex_column">

              <div className='CardList'>
                <Link to='/misas' className='icon' onClick={() => props.setLoading(true)}> 
                  <span className='CardListTitle'>Ir a lecturas</span>
                </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};



