import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';


export default function CelebracionesList(props: any) {

  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    { type: "text", label: "Nombre", name: "title", value: "" },
    { type: "date", label: "Fecha", name: "fecha", value: "" },
  ]
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>({});
  const [principalVlaues, setPrincipalValues] = useState<any>({});
  const [dayView, setDayView] = useState<any>(0);
  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/


  const [list, setList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [listValidos, setListValidos] = useState<any[]>([]);
  const [listEstado, setListEstado] = useState<boolean>(false);

  const [diaValue, setDia] = useState(0);
  const [mesValue, setMes] = useState(0);

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);

  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().slice(0, 10);

  const twoDaysAgo = new Date(currentDate);
  twoDaysAgo.setDate(currentDate.getDate() - 3);
  const twoDaysAgoString = twoDaysAgo.toISOString().slice(0, 10);

  const fiveDaysLater = new Date(currentDate);
  fiveDaysLater.setDate(currentDate.getDate() + 365);
  const fiveDaysLaterString = fiveDaysLater.toISOString().slice(0, 10);

  const dates = [];
  const daysDifference = Math.ceil((fiveDaysLater.getTime() - twoDaysAgo.getTime()) / (1000 * 60 * 60 * 24));
  for (let i = 0; i < daysDifference; i++) {
    const date = new Date(twoDaysAgo);
    date.setDate(twoDaysAgo.getDate() + i);
    dates.push(date.toISOString().slice(0, 10));
  }


  if (listValidos.length > 0) {
    const fechasValidas = listValidos.map((item) => item.fecha);
    principalVlaues.fecha_carrusel = fechasValidas[dayView]

    // console.log("fechasValidas.length");
    // console.log(fechasValidas.length);
  } else {
    principalVlaues.fecha_carrusel = dates[dayView]
  }



  // principalVlaues.fecha_carrusel = dates[dayView]

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');

  const modulo = 'Celebraciones';



  const fetchData = (newDayView: number) => {
    props.setLoading(true);

    
      setTimeout(() => {
        axios
          .get(ProjectConfig.api_url, {
            params: {
              request: 'celebraciones_list2',
              filters: filtersValues,
              principal: principalVlaues,
              page: page,
              dayView: newDayView
            },
          })
          .then((response) => {
            setList(response.data.list);
            setTotalPages(response.data.total_pages);
            setMes(response.data.mes_actual);
            setDia(response.data.dia_actual);

            props.setLoading(false);
          })
          .catch((err) => {
            console.log(err.data);
          });
        localStorage.setItem('page', page.toString());
      }, 200);
    
  };

  const navigate = (direction: string) => {
    setDayView((prevDayView: number) => {
      let newDayView = prevDayView;

      if (direction === 'back') {
        newDayView -= 1;

        if (newDayView < 0) {
          newDayView = listValidos.length - 1;
        }
      } else if (direction === 'today') {
        newDayView = 0;
      } else if (direction === 'forward') {
        newDayView += 1;
        if (newDayView > listValidos.length - 1) {
          newDayView = 0;
        }
      }

      // Asegurarse de que newDayView esté en el rango de 0 a 7
      // newDayView = (newDayView + 8) % 7;

      fetchData(newDayView);
      return newDayView; // Actualiza el estado del día con el nuevo valor
    });
  };

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true)

    if (!listEstado) {

      axios.get(ProjectConfig.api_url,
        {
          params: {
            request: 'celebraciones_list_estado',
          }
        })
        .then((response) => {
          setListEstado(response.data.accion)
          setListValidos(response.data.list)
          console.log("response")
          console.log(response.data.accion)

        })
        .catch((err) => {
          console.log(err.data);
        })



    } else {
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'celebraciones_list2',
          filters: filtersValues,
          principal: principalVlaues,
          page: page,
          user_id: user_id,
          modulo: modulo
        }
      })
      .then((response) => {
        setList(response.data.list)
        setTotalPages(response.data.total_pages)
        setMes(response.data.mes_actual)
        setDia(response.data.dia_actual)
        props.setLoading(false)

      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())
    }

  }, [page, refreshList, filtersValues, listEstado])
  /*=========================================================*/
  const year = parseInt(principalVlaues.fecha_carrusel.substring(0, 4));
  const month = parseInt(principalVlaues.fecha_carrusel.substring(5, 7));
  const day = parseInt(principalVlaues.fecha_carrusel.substring(8, 10));

  const currentYear = new Date().getFullYear();
  const mesesDelAnio = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE"
  ];

  return (
    <div id='Celebraciones' className='module_container'>

      <div className="module_topBar">
        <TopBar title='Celebraciones' filters={filters} setFilters={setFilters} setLoading={props.setLoading} />
      </div>


      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />

      <div className='module_content'>
        <div id="celebraciones_list_container" className="flex_column">





          {/* <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          /> */}

          {(filtersValues.fecha != null || filtersValues.name != null) &&
            <>
              <div className="carousel-title">
                {/* <h1 style={{ textAlign: 'center', fontSize: '2em', color: '#FFB91A' }}>{month}/{day}</h1> */}
              </div>
              {list.map((list_item, key) => (

                <Link className='CardList' key={key} to={`/celebraciones/${list_item.celebracion_id}`}
                  onClick={() => { props.setLoading(true) }}>

                  <div className='CardListRight'>
                    <span className='CardListTitle'>{list_item.title}</span>
                    <span className='CardListSubTitle'>{list_item.date}</span>
                  </div>
                </Link>
              ))}

            </>

          }

          {(filtersValues.fecha == null && filtersValues.name == null) &&
            <>
              <div className="carousel-title">
                <h1 style={{ textAlign: 'center', fontSize: '1.6em', color: '#6e5002' }}> <strong>{mesesDelAnio[month - 1]} {day} DE {currentYear}</strong></h1>
              </div>
              {list.map((list_item, key) => (

                <Link className='CardList' key={key} to={`/celebraciones/${list_item.celebracion_id}`}
                  onClick={() => { props.setLoading(true) }}>

                  <div className='CardListRight'>
                    <span className='CardListTitle'>{list_item.title}</span>
                    <span className='CardListSubTitle'>{list_item.date}</span>
                  </div>
                </Link>
              ))}
              <div className="nav-buttons">
                <Button variant="outlined" onClick={() => navigate('back')}> <strong>Atrás</strong></Button>
                <Button variant="outlined" onClick={() => navigate('today')}> <strong>Hoy</strong> </Button>
                <Button variant="outlined" onClick={() => navigate('forward')}> <strong>Siguiente</strong> </Button>
              </div></>

          }

        </div>
      </div>
    </div>
  );
};




