import { useEffect } from 'react';
import Romcal from 'romcal';




export default function Tests() {

  const {GeneralRoman_Es} = require('@romcal/calendar.general-roman/cjs/es.js');

  const romcal = new Romcal({
    localizedCalendar: GeneralRoman_Es,
  });

/*Test with: '2022-09-25', '2022-09-26', '2022-09-27', '2022-09-28', '2022-09-29', '2022-09-30', '2022-10-01' */

useEffect (() => {

  romcal.generateCalendar(2022).then((data1) => {
    
    console.log(data1["2022-09-25"][0]); 
  });
}, []);
 
  //RESULT FOR "weekOfSeason"
  // 25, 26, 26, 26 ,26, 26, 26



  return (
    <div>tests</div>
  );
}
