import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from 'react';
import Filters from 'elements/Filters/Filters';



export default function TopBar(props: any) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
    
  return (
    <div id="TopBar">
        <div id="TopBarLeft">
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={() => {navigate(-1)
              props.setLoading(true)
            }}
          >
          <ArrowBackIosNewIcon />
          </IconButton>
          <h1>{props.title}</h1>
        </div>
        <div id="TopBarRight">
          {props.filters && (
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(true)}
            >
            <TuneIcon />
            </IconButton>
          )}
        </div>
        {props.filters && (
          <Filters open={open} setOpen={setOpen} filters={props.filters} setFilters={props.setFilters}/>
        )}
    </div>
  );
}
