import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

interface SubCategoria {
  id: number;
  padre_id: number;
  label: string;
  titulo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  estado: string;
  delete: number;
}

interface Props {
  titulo: string;
  contenido: string;
  subCategorias: SubCategoria[];
}

export default function BasicAccordionSub(props: Props) {
  const { titulo, contenido, subCategorias } = props;

  const brickColors = [
    "#FFB91A",
    "#FFC53E",
    "#FFC53E",
    "#FFC53E",
    "#FFC53E",
    "#FFD461",
    "#FFD461",
    "#FFD461",
    "#FFE284",
    "#FFE284",
    "#FFE284",
    "#FFE284",
    "#FFE284",
    "#FFEDA7",
    "#FFEDA7",
    "#FFEDA7",
    "#FFEDA7",
    "#FFEDA7",
    "#FFF8CB",
    "#FFF8CB",
    "#FFF8CB",
    "#FFF8CB",
    "#FFF8CB",
    "#FFF8CB",
    "#FFF8CB",
    "#FFF8CB",
    "#FFFFEE",
    "#FFFFEE",
    "#FFFFEE"
  ];

  const getBackgroundColor = (index: number) => {
    return brickColors[index % brickColors.length];
  };

  return (
    <Box width="100%" maxWidth="600px" margin="0 auto">
      <Grid item xs={12}>
        <Accordion style={{ backgroundColor: getBackgroundColor(0), fontSize: '20px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content`}
            id={`panel-header`}
          >
            <Typography style={{ textTransform: 'uppercase' }}>{titulo}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span dangerouslySetInnerHTML={{ __html: contenido }} />
              {subCategorias.map((subCategoria, index1) => (
                <Grid item xs={12} key={subCategoria.id}>
                  <Accordion style={{ fontSize: '20px', backgroundColor: getBackgroundColor(index1 + 1) }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index1 + 1}-content`}
                      id={`panel${index1 + 1}-header`}
                    >
                      <Typography>{subCategoria.titulo}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><span dangerouslySetInnerHTML={{ __html: subCategoria.contentHTML }} /></Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>


    </Box>
  );
}

