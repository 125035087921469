import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {ProjectConfig} from 'Global';
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import DateBar from "elements/DateBar";
import App from "elements/HtmlEditor/App";
import Cookies from 'universal-cookie';
import 'assets/css/main.css';

export default function AnoLiturgicoViewer(props: any) {


  /*=======================================
    GET LITUGIA CATEGORY FROM URL
  =======================================*/
  let {category } = useParams();
  /*=======================================
  =======================================*/

  const[category_title, setCategoryTitle] = useState("-");
  var category_t = "-";
  useEffect(() => {    
    switch(category)
    {
      case 'oficio-lectura':
        setCategoryTitle("Oficio de lectura");
        category_t = "Lit Oficio de lectura";
        break;
      case 'laudes':
        setCategoryTitle("Laudes");
        category_t = "Lit Laudes";
        break;
      case 'intermedias':
        setCategoryTitle("Horas intermedias");
        category_t = "Lit Horas intermedias";
        break;
      case 'visperas':
        setCategoryTitle("Visperas");
        category_t = "Lit Visperas";
        break;
      case 'completas':
        setCategoryTitle("Completas");
        category_t = "Lit Completas";
        break;
    }
  }, [category]);

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');
  

  /*=======================================
  =======================================*/

  const [loading, setLoading] = useState(true);

  useEffect(() => { props.setLoading(loading); }, [loading]);
  
  const [date, setDate] = useState(localStorage.getItem("date"));
  const [data, setData] = useState<any>();
  
  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    const modulo  = 'Lit ' + category_title;
    setLoading(true);
    axios.get(ProjectConfig.api_url, {
      params: {
        request: "liturgia_horas_dia",
        category: category,
        date: date,
        user_id: user_id,
        modulo: category_t
      },
    })
    .then((response) => {
      console.log("res",response.data);
      const data = response.data;
      console.log(data.color.toLowerCase() === "blanco", {backgroundColor: "#FFFFFF"}, {...data, ...(data.color.toLowerCase() === "blanco" ? {backgroundColor: "#FFFFFF"} : {})});
      setData({...data, ...(data.color.toLowerCase() === "blanco" ? {backgroundColor: "#FFFFFF"} : {})});
    })
    .catch((err) => {
      console.log(err.data);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [date]);
  /*=======================================
  =======================================*/

  const getLectura = () => {
    if(data && data.lectura && typeof data.lectura === "object"){
      return true;
    }
    return false;
  }
  
  return (
      <div id="AnoLiturgicoViewer" className="module_container">


        <div className="module_topBar">
          <TopBar title="LITURGIA DE LAS HORAS"/>
          <Toolbar/>
          <DateBar onChange={setDate}/>

          {data &&
          (
            <div id="liturgia_header" style={{
              backgroundColor: data.backgroundColor,
              color: data.textColor,
            }}>
              <span className="ld_category">{category_title.toUpperCase()}</span>
              <span className="ld_date">{data.fullDate}</span>
              <span className="ld_weekName">{data.weekName}</span>
              <div className="ld_colors">
                <span className="ld_color">{data.color}</span>
              </div>
              
              
            </div>
          )}
        </div>
        
        <div className='module_content'>
          
          {getLectura() && (
          <div id="misaLecturaBox">
            {/* parse html */}
            <App
              handleEditorChange={()=>{}}
              content={data.lectura}
              editable={false}
            />
          </div>
          )}

        </div>

      </div>
    
  );
};