import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import FiestasViewer from './FiestasViewer';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';

export default function FiestasList(props: any) {

  const { inHome, fixedDate } = props;

  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    { type: "text", label: "Titulo", name: "titulo", value: "" },
    { type: "date", label: "Fecha", name: "fecha", value: "" },
  ]
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>({});
  const [principalVlaues, setPrincipalValues] = useState<any>({});
  const [dayView, setDayView] = useState<any>(3);

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/


  const [list, setList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);
  const [diaValue, setDia] = useState(0);
  const [mesValue, setMes] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState<number>(1);
  const [refreshList, setRefreshList] = useState(0);

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');

  var modulo = '';
  if (!inHome) {
    modulo = 'Fiestas';
  }


  var fecha_actual = 0
  /*=========================================================
  LOAD LIST            
  =========================================================*/

  if (fixedDate) {
    filtersValues.fecha = fixedDate;
    principalVlaues.fecha_carrusel = fixedDate;

  } else {
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);

    const twoDaysAgo = new Date(currentDate);
    twoDaysAgo.setDate(currentDate.getDate() - 3);
    const twoDaysAgoString = twoDaysAgo.toISOString().slice(0, 10);

    const fiveDaysLater = new Date(currentDate);
    fiveDaysLater.setDate(currentDate.getDate() + 365);
    const fiveDaysLaterString = fiveDaysLater.toISOString().slice(0, 10);

    const dates = [];
    const daysDifference = Math.ceil((fiveDaysLater.getTime() - twoDaysAgo.getTime()) / (1000 * 60 * 60 * 24));
    for (let i = 0; i < daysDifference; i++) {
      const date = new Date(twoDaysAgo);
      date.setDate(twoDaysAgo.getDate() + i);
      dates.push(date.toISOString().slice(0, 10));
    }



    principalVlaues.fecha_carrusel = dates[dayView]
  }


  const year = parseInt(principalVlaues.fecha_carrusel.substring(0, 4));
  const month = parseInt(principalVlaues.fecha_carrusel.substring(5, 7));
  const day = parseInt(principalVlaues.fecha_carrusel.substring(8, 10));




  useEffect(() => {
    props.setLoading(true)

    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'fiestas_list2',
          filters: filtersValues,
          principal: '',
          page: page,
          full_list: inHome,
          user_id: user_id,
          modulo: modulo
        }
      })
      .then((response) => {
        setList(response.data.list)
        setTotalPages(response.data.total_pages)
        setMes(response.data.mes_actual)
        setDia(response.data.dia_actual)

        props.setLoading(false)

      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())

  }, [page, refreshList, filtersValues])
  /*=========================================================*/
  const fetchData = (newDayView: number) => {
    props.setLoading(true);

    setTimeout(() => {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: 'fiestas_list2',
            filters: filtersValues,
            principal: principalVlaues,
            page: page,
            full_list: inHome,
            dayView: newDayView,
          },
        })
        .then((response) => {
          setList(response.data.list);
          setTotalPages(response.data.total_pages);
          setMes(response.data.mes_actual);
          setDia(response.data.dia_actual);

          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
      localStorage.setItem('page', page.toString());
    }, 200);

  };


  const navigate = (direction: string) => {
    setDayView((prevDayView: number) => {
      let newDayView = prevDayView;

      if (direction === 'back') {
        newDayView -= 1;

        if (newDayView < 0) {
          newDayView = 365;
        }
      } else if (direction === 'today') {
        newDayView = 3;
      } else if (direction === 'forward') {
        newDayView += 1;
        if (newDayView > 365) {
          newDayView = 0;
        }
      }

      // Asegurarse de que newDayView esté en el rango de 0 a 7
      // newDayView = (newDayView + 8) % 7;

      fetchData(newDayView);

      return newDayView; // Actualiza el estado del día con el nuevo valor
    });
  };

  // const year = parseInt(principalVlaues.fecha_carrusel.substring(0, 4));
  // const month = parseInt(principalVlaues.fecha_carrusel.substring(5, 7));
  // const day = parseInt(principalVlaues.fecha_carrusel.substring(8, 10));
  const currentYear = new Date().getFullYear();
  const mesesDelAnio = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE"
  ];


  return (
    <div id='Fiestas' className='module_container'>
      {!inHome &&
        <>
          <div className="module_topBar">
            <TopBar title='Propios' filters={filters} setFilters={setFilters} setLoading={props.setLoading} />
          </div>
          <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />
        </>
      }
      {(inHome && list.length > 0) &&
        <h2>Santo del día</h2>
      }

      {list.length > 0 &&
        <div className='module_content'>
          <div id="task_list_container" className="flex_column">

            {list.map((list_item, key) => (
              <Link
                to={`/fiestas/${list_item.fiesta_id}`}
                onClick={() => { props.setLoading(true) }}
                className='CardList'
                key={key}
                style={{ backgroundColor: list_item.color_claro }}
              >
                <div className='CardListLeft'>
                  <img className='ListImage' src={list_item.image} alt={`Imagen de ${list_item.titulo}`} />
                </div>

                <div className='CardListRight'>
                  <span className='CardListSubTitle'>
                    {list_item.categoria == null && (
                      <strong>{list_item.name}</strong>
                    )}
                    {list_item.categoria != null && (
                      <strong>{list_item.name}, {list_item.categoria}.</strong>
                    )}
                  </span>
                  <span className='CardListSubTitle'>
                    <strong>{list_item.date}</strong>
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      }
    </div>
  );
};



