import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {ProjectConfig} from 'Global';

import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";



export default function NotificacionesViewer (props:any) {

  
  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let {notificacion_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    notificacion_id: notificacion_id? notificacion_id : "null",
    title: "",
    content: "",
    date: "",
  };
  
  const [values, setValues] = useState(defaultValues)


  
  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (notificacion_id) {
      props.setLoading(true);
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "notificaciones_details",
            id: notificacion_id,
          },
        })
        .then((response) => {
          setValues(response.data);
          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/


  return (
      <div id="NotificacionesView" className="module_container">

        <div className="module_topBar">
          <TopBar title='Notificaciones'/>
          <Toolbar/>
        </div>

        <div className='module_content'>
          <h2 className="notificacionTitle">{values.title}</h2>
          <span className="notificacionSubtitle">{values.date}</span>
          <div id="content">
            {values.content}
          </div>
          
        </div>

      </div>
    
  );
};


