import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from 'react';

import {ReactComponent as IconToolLetra} from 'icons/ic_tool_letra.svg';
import {ReactComponent as IconToolModo} from 'icons/ic_tool_modo.svg';
import {ReactComponent as IconToolCompartir} from 'icons/ic_tool_compartir.svg';

declare global {
  interface Window {
    AndroidShareHandler: any; // 👈️ turn off type checking
  }
}

export default function Toolbar() {

  const setSize = () => {
    //INCREASE FONT SIZE OF ALL TEXTS
    let texts = Array.from(document.getElementsByClassName("module_content") as HTMLCollectionOf<HTMLElement>)
    for (let i = 0; i < texts.length; i++) {
      let fontSize = parseInt(window.getComputedStyle(texts[i]).fontSize);
      if(fontSize < 25){
      texts[i].style.fontSize = fontSize + 5 + "px";
      }
      else{
        texts[i].style.fontSize = "15px";
      }
    }
    //INCREASE FONT SIZE OF ALL TEXTS1
    let texts1 = Array.from(document.getElementsByClassName("ContentEditable__root") as HTMLCollectionOf<HTMLElement>)
    for (let i = 0; i < texts1.length; i++) {
      let fontSize = parseInt(window.getComputedStyle(texts1[i]).fontSize);
      if(fontSize < 25){
      texts1[i].style.fontSize = fontSize + 5 + "px";
      }
      else{
        texts1[i].style.fontSize = "15px";
      }
    }
  }


  const setMode = () => {
    //TOGGLE DARK MODE
    let body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("dark")) {
      body.classList.remove("dark");
    } else {
      body.classList.add("dark");
    }
  }
  
  const setShare = () => {
    //SHARE
    if (navigator.share) {
      console.log("Congrats! Your browser supports Web Share API");
      navigator
        .share({
          url: window.location.href,
          title: document.title,
          text: document.title,
        })
        .then(() => {
          console.log("Sharing successfull");
        })
        .catch(() => {
          console.log("Sharing failed");
        });
    } else {
      console.log("Browser does not support Web Share API");
      
      window.AndroidShareHandler.share(window.location.href);
      
    }
  }
  

  return (
    <div id="Toolbar">
        <IconButton onClick={setSize}>
          <IconToolLetra />
        </IconButton>

        <IconButton onClick={setMode}>
          <IconToolModo />
        </IconButton>

        <IconButton onClick={setShare}>
          <IconToolCompartir />
        </IconButton>
    </div>
  );
}
