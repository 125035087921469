import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Fragment } from "react";

export default function FiltersApplied(props: any) {

  return (
    <Fragment>
        {/* SHOWS ONLY IF PROPS.FILTERSVALUES EXISTS AND IS NOT EMPTY */}
    {Object.keys(props.filtersValues).length > 0 && (
        <div className="filtersApplied">
            {Object.keys(props.filtersValues).map((key, index) => {
                //if value is longer than 10 characters, cut it
                let value = (props.filtersValues[key].length < 10)? props.filtersValues[key] : props.filtersValues[key].substring(0, 10) + "...";

                return (
                    <div className="filterItem" key={index}>
                        {/* cut key name and add dots  */}
                        <span>{value}</span>
                        <IconButton 
                            size="small"
                            onClick={() => {
                                delete props.filtersValues[key];
                                props.setFiltersValues({...props.filtersValues});
                            }
                        }>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </div>
                )
            })}

        </div>
    )
    }
    </Fragment>
  );
}
