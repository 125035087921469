import { Dialog, DialogContent, IconButton, TextField } from '@mui/material';



import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useEffect, useState } from 'react';
import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Romcal from "romcal"
const { GeneralRoman_Es } = require('@romcal/calendar.general-roman/cjs/es.js')

export default function DateBar(props: any) {

    //check date in local storage or set to today
    //today formated as YYYY-MM-DD
    const today: any = new Date();
    const todayFormated = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    const [date, setDate] = useState<any>((localStorage.getItem("date") && !props.date) ? localStorage.getItem("date") : todayFormated);

    const [openDialog, setOpenDialog] = useState(false);
    const [forceOpen, setForceOpen] = useState(props.forceOpen || false);

    useEffect(() => {
        if(localStorage.getItem("date") && !props.date){
            setDate(localStorage.getItem("date"));
        }
        else{
            localStorage.setItem("date", date);
        }
    }, []);

    useEffect(() => {
        if (forceOpen) {
            setOpenDialog(true);
            setDate(null);
        }
    }, [forceOpen]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(date);
        }
    }, [date]);
    
    const handleDateChange = (newDate: any) => {
        localStorage.setItem("date", newDate);
        setDate(newDate);
        setOpenDialog(false);
        setForceOpen(false);
    };

  return (
    <div id="Toolbar" style={props.hidden ? {display: "none"} : {}}>
        <IconButton onClick={()=>{setOpenDialog(true)}} size="small" aria-label="back" sx={{color: "#684E07",}}>
          <CalendarMonthIcon />
          {date}
        </IconButton>

        <Dialog
            open={openDialog}
            onClose={()=>{
                if(!forceOpen) {
                    setOpenDialog(false)
                }
            }}
        >
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker className='filters_datePicker'
                    label="Fecha"
                    displayStaticWrapperAs="mobile"
                    value={date}
                    showToolbar={false}
                    
                    onChange={(newValue: any) => {
                        console.log(newValue)
                        newValue && handleDateChange(newValue.format('YYYY-MM-DD'));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                </LocalizationProvider>
            </DialogContent>
        </Dialog>
    </div>
  );
}
