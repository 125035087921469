import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectConfig } from "Global";
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import BasicAccordionSub from "elements/BasicAccordionSub";

// Worker
import BasicAccordionPdf from "elements/BasicAccordionPdf";

export default function OracionesViewer(props: any) {
  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { oracion_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    oracion_id: oracion_id ? oracion_id : "null",
    image: "",
    title: "",
    date: null,
    bioHTML: "",
    bioJSON: null,
  };

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(defaultValues);

  const [categoriasAll, setCategoriasAll] = useState<Categoria[]>([]);

  interface SubCategoria {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    contentJSON: any | null;
    contentJSON_send: any | null;
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    delete: number;
  }

  interface Categoria {
    id: number;
    label: string;
    titulo: string;
    contentJSON: any | null;
    contentJSON_send: any | null;
    contentHTML: string;
    contentHTML_send: string;
    subCategorias: SubCategoria[];
    estado: string;
    delete: number;
  }

  // const [filePdf, setIsFilePdf] = useState('');

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/

  interface pdfData {
    file_id: any;
    file: any;
    name: string;
    estado: string;
  }

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  useEffect(() => {
    props.setLoading(true);
    if (oracion_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "oraciones_details",
            id: oracion_id,
          },
        })
        .then((response) => {
          setValues(response.data);
          props.setLoading(false);
          // console.log(response.data.categorias)
          response.data.categorias.map(
            (categoriaNew: Categoria, index1: number) =>
              setCategoriasAll((prevCategorias) => {
                // Copia el estado anterior y modifica la categoría en la posición index1
                const newCategorias = [...prevCategorias];
                newCategorias[index1] = categoriaNew;
                return newCategorias;
              })
          );

          setFilesPdf(response.data.files);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/
  const coloresNaranja = [
    "#FFB91A",
    "#FF6F00",
    "#FF884D",
    "#FFA726",
    "#FFB74D",
    "#FFCC80",
    "#FFCC80",
    "#FFCC80",
    "#FFD699",
    "#FFD699",
    "#FFD699",
    "#FFD699",
    "#FFDDA0",
    "#FFDDA0",
    "#FFDDA0",
    "#FFDDA0",
  ];

  // Puedes acceder a los colores usando coloresNaranja[0], coloresNaranja[1], etc.
  const subCategoria1: SubCategoria = {
    id: 1,
    padre_id: 0,
    label: "Subcategoría 1",
    titulo: "Título de la subcategoría 1",
    contentJSON: null,
    contentJSON_send: null,
    contentHTML: "Contenido de la subcategoría 1",
    contentHTML_send: "Contenido de la subcategoría 1",
    estado: "nuevo",
    delete: 0,
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Verificar si el dispositivo es móvil o tablet
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    function handleDeviceChange(e: any) {
      setIsMobile(e.matches);
    }

    // Registrar el oyente para cambios en la consulta de medios
    mediaQuery.addEventListener("change", handleDeviceChange);

    // Establecer el estado inicial
    handleDeviceChange(mediaQuery);

    // Desregistrar el oyente al desmontar el componente
    return () => {
      mediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, []);

  return (
    <div id="OracionesView" className="module_container">
      <div className="module_topBar">
        <TopBar title="Oraciones" />
        <Toolbar />
      </div>

      <div className="module_content">
        {values.image && (
          <div id="mv_image">
            <img src={values.image} />
          </div>
        )}
        <h2>{values.title}</h2>

        <div id="HTMLContent">
          <div dangerouslySetInnerHTML={{ __html: values.contentHTML }} />
        </div>
      </div>

      {categoriasAll.map((categoria, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BasicAccordionSub
              titulo={categoria.titulo}
              contenido={categoria.contentHTML}
              subCategorias={categoria.subCategorias}
            />
          </div>
        </div>
      ))}

      {filesPdf.map((file, index) => (
        <div key={index}>
          <BasicAccordionPdf
            titulo={file.name}
            filePdf={file.file}
          ></BasicAccordionPdf>
        </div>
      ))}
    </div>
  );
}
