import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from 'react';
import { LocalizationProvider, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';

interface Props {
  open: boolean;
  setOpen: any;
  filters: Array<any>;
  setFilters: any;
}

export default function Filters(props: Props) {

  const [tempFilters, setTempFilters] = useState<any>({});
  
  const handleTempFilters = (filter: any, value: any) => {
    if (value === null || value === undefined || value === "") {
      delete tempFilters[filter.name];
      setTempFilters({...tempFilters});
    } else {
      setTempFilters({...tempFilters, [filter.name]: value});
    }
  }


  //TODAY 
  const today: any = new Date();
  const [selectedDate, handleDateChange] = useState(today);

  
  
    
  return (
    <Dialog className='filtersDialog' open={props.open} onClose={() => props.setOpen(false)}>
      <DialogContent className='forced_box_editor'>
        <h2>BUSQUEDA</h2>
        {props.filters && props.filters.map((filter, index) => (
          <div className="filter" key={index}>
            
            {filter.type === "text" && (
              <TextField 
                variant="outlined"
                label={filter.label}
                fullWidth
                defaultValue={tempFilters[filter.name]}
                onChange={(e) => {
                  handleTempFilters(filter, e.target.value)
                }}
              />
            )}

            {filter.type === "date" && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker className='filters_datePicker'
                  label={filter.label}
                  displayStaticWrapperAs="mobile"
                  value={tempFilters[filter.name] || moment(new Date())}
                  showToolbar={false}
                  
                  onChange={(newValue) => {
                    newValue && handleTempFilters(filter, newValue.format('YYYY-MM-DD'))
                    handleDateChange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

            )}
          </div>
        ))}

        <div className='buttons'>
          <Button
            variant="contained"
            onClick={() => {
              props.setFilters(tempFilters)
              props.setOpen(false)
            }}
          >
            Aplicar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
