import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button, Dialog, DialogContent, IconButton, TextField } from '@mui/material';
import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { ProjectConfig } from 'Global';

import Romcal from "romcal"
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Link, useNavigate } from 'react-router-dom';
const { GeneralRoman_Es } = require('@romcal/calendar.general-roman/cjs/es.js')

/*========================================
GET TODAY DATE
========================================*/
const today: any = new Date();
const todayFormated = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();


/*========================================
CHECK IF calendarBarInfo IS IN LOCAL STORAGE
========================================*/
let flag = false;
//check if calendarBarInfo exists in local storage
if (localStorage.getItem("calendarBarInfo")) {
  const romcal_data = JSON.parse(localStorage.getItem("calendarBarInfo") || "");
  //check id romcal_data.today is different from today
  if (romcal_data.today != todayFormated) {
    flag = true;
  }
}
else {
  flag = true;
}
/*========================================
========================================*/



export default function CalendarBar(props: { setFechaView: (fecha: string) => void }) {

  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef: any = React.createRef();
  const [calendarInfo, setCalendarInfo] = useState<any>([]);



  useEffect(() => {
    //if flag is true, get romcal data
    if (flag) {
      getRomcalData()
    }


  }, []);

  const getRomcalData = () => {

    /*========================================
    GET YEAR
    ========================================*/
    const year = todayFormated.split("-")[0];

    /*========================================
    ROMCAL DATA
    ========================================*/
    let romcalArray: any = [];
    const romcal = new Romcal({ scope: 'gregorian', localizedCalendar: GeneralRoman_Es });
    romcal.generateCalendar(year).then((data) => {

      //GET NEXT 7 DAYS
      const next7Days = [];
      for (let i = 0; i < 7; i++) {
        const nextDay = new Date(today);
        nextDay.setDate(nextDay.getDate() + i);
        //NEXT DAY FORMATED TO YYYY-MM-DD DAY WITH 0
        const day = ("0" + nextDay.getDate()).slice(-2);
        const month = ("0" + (nextDay.getMonth() + 1)).slice(-2);

        const nextDayFormated = nextDay.getFullYear() + "-" + month + "-" + day;



        next7Days.push(nextDayFormated);
      }

      // console.log('next7Days');
      // console.log(next7Days);

      //GET ROMCAL DATA FOR NEXT 7 DAYS
      next7Days.forEach((day) => {

        const colors = data[day][0].colors;
        const date = data[day][0].date;
        const dayOfWeek = data[day][0].calendar.dayOfWeek;
        const weekOfSeason = data[day][0].calendar.weekOfSeason;
        const seasons = data[day][0].seasons;
        const rank = data[day][0].rank;

        const romcalData = {
          colors: colors,
          date: date,
          dayOfWeek: dayOfWeek,
          weekOfSeason: weekOfSeason,
          seasons: seasons,
          rank: rank
        }

        romcalArray.push(romcalData);
      })

      console.log("rm", romcalArray);

      /*========================================
      API
      ========================================*/
      axios.get(ProjectConfig.api_url,
        {
          params: {
            request: 'calendar_bar_info',
            romcalArray: JSON.stringify(romcalArray)
          }
        })
        .then((response) => {
          console.log("response", response.data);
          setCalendarInfo(response.data);

        })
        .catch((err) => {
          console.log(err.data);
        })


      const calendarBarInfo = {
        today: todayFormated,
        romcalData: romcalArray
      }

      //SET ROMCAL DATA IN STATE
      //setRomcalData(romcalArray);
      //localStorage.setItem("calendarBarInfo", JSON.stringify(calendarBarInfo));

    });
    /*========================================
    ========================================*/


  }

  const data = [
    {
      weekName: "Semana 24 Tiempo de adviento",
      maxima: "",


    }
  ]
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: any, next: any) => setActiveSlide(next),
  };



  const [fecha_pos, setFecha_pos] = useState(0)

  const prevSlide = () => {
    sliderRef.current.slickPrev();

    // localStorage.setItem('fecha_view', nuevaFecha);

    if (fecha_pos > 0) {
      props.setFechaView(calendarInfo[fecha_pos - 1]['date']);
      setFecha_pos(fecha_pos - 1);
    } else {
      props.setFechaView(calendarInfo[6]['date']);
      setFecha_pos(6);
    }

    // localStorage.setItem('fecha_view', calendarInfo[fecha_pos]['date']);
    // props.setFechaView(calendarInfo[fecha_pos]['date']);

  };


  const nextSlide = () => {
    sliderRef.current.slickNext();
    if (fecha_pos < 6) {
      props.setFechaView(calendarInfo[fecha_pos + 1]['date']);
      setFecha_pos(fecha_pos + 1);
    } else {
      props.setFechaView(calendarInfo[0]['date']);
      setFecha_pos(0);
    }

    // localStorage.setItem('fecha_view', calendarInfo[fecha_pos]['date']);
    // props.setFechaView(calendarInfo[fecha_pos]['date']);
  };

  const [date, setDate] = useState(todayFormated);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDateChange = (newDate: any) => {
    navigate("/calendario/" + newDate);
    setOpenDialog(false);
  }

  const handleSlideChange = (oldIndex:number, newIndex:number) => {
  
    props.setFechaView(calendarInfo[newIndex]['date']);
    setFecha_pos(newIndex);
    setActiveSlide(newIndex)
  };

  return (
    <div id="CalendarBar" >
      <Slider  ref={sliderRef} {...settings} beforeChange={handleSlideChange}>
        {calendarInfo.map((item: any, index: any) => (

          <div id="CalendarbarTop" className={item.cssClass} key={index}>
            <Link to={"/calendario/" + calendarInfo[activeSlide]?.date}>
              <h2 className={item.cssClass}>{item.texto_dia}</h2>
              <h2 className={item.cssClass}>{item.weekName}</h2>
              <h2 className={item.cssClass}>{item.santo} {item.propio === "0" ? <span></span> : <> - FIESTA PROPIA </>}</h2>
              <p>{item.maxima}</p>
            </Link>
          </div>
        ))}

      </Slider>
      <div id="CalendarbarBottom" className={calendarInfo[activeSlide]?.cssClass}>
        <div id="CalendarbarBottomLeft">
          <IconButton size="small" aria-label="back" sx={{ color: "#fff" }} onClick={prevSlide}>
            <ArrowBackIosNewIcon />
          </IconButton>

          <IconButton size="small" aria-label="back" sx={{ color: "#fff" }} onClick={() => { setOpenDialog(true) }}>
            <CalendarMonthIcon />
          </IconButton>

          <IconButton size="small" aria-label="back" sx={{ color: "#fff" }} onClick={nextSlide}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>

        <Button
          size="small"
          component={Link}
          to={"/calendario/" + todayFormated}
          sx={{ textTransform: "none", fontSize: "1rem", color: "#000", backgroundColor: "#00000050", borderRadius: "0" }}

        >
          Hoy
        </Button>
        <div id="CalendarbarBottomRight" className={calendarInfo[activeSlide]?.cssClass}>

          <Link to={"/calendario/" + calendarInfo[activeSlide]?.date}>
            {calendarInfo[activeSlide]?.dateFormatted}
          </Link>
        </div>
      </div>


      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker className='filters_datePicker'
              label="Fecha"
              displayStaticWrapperAs="mobile"
              value={date}
              showToolbar={false}

              onChange={(newValue: any) => {
                newValue && handleDateChange(newValue.format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
      </Dialog>


    </div>
  );
}


