import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';


export default function AniversariosList(props: any) {

  const { inHome, fixedDate } = props;

  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    { type: "text", label: "Nombre", name: "name", value: "" },
    { type: "date", label: "Fecha", name: "fecha", value: "" },
  ]
  const [filtersValues, setFiltersValues] = useState<any>({});
  const [principalVlaues, setPrincipalValues] = useState<any>({});
  const [dayView, setDayView] = useState<any>(0);

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/


  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<DataInfo[]>([]);
  const [cumple, setCumple] = useState<DataInfo[]>([]);
  const [profe, setProfe] = useState<DataInfo[]>([]);
  const [orden, setOrden] = useState<DataInfo[]>([]);
  const [falle, setFalle] = useState<DataInfo[]>([]);
  const [list_name, setList_name] = useState<DataInfo[]>([]);
  const [listValidos, setListValidos] = useState<any[]>([]);
  const [listEstado, setListEstado] = useState<boolean>(false);
  const [total_pages, setTotalPages] = useState(0);
  const [diaValue, setDia] = useState(0);
  const [mesValue, setMes] = useState(0);

  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);

  interface DataInfo {
    aniversario_id: string;
    name: string;
    fecha_santoral: string | null;
    birth_date: string;
    death_date: string;
    ordination_date: string;
    profession_date: string;
    image: string;
    date_coincidence: string;
  }

  /*=========================================================
  LOAD LIST            
  =========================================================*/
  const fetchData = (newDayView: number) => {
    props.setLoading(true);

    setTimeout(() => {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: 'aniversarios_list2',
            filters: filtersValues,
            principal: principalVlaues,
            page: page,
            full_list: inHome,
            dayView: newDayView,
          },
        })
        .then((response) => {
          setList(response.data.list);
          setTotalPages(response.data.total_pages);
          setMes(response.data.mes_actual);
          setDia(response.data.dia_actual);

          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
      localStorage.setItem('page', page.toString());
    }, 200);
  };
  const navigate = (direction: string) => {
    setDayView((prevDayView: number) => {
      let newDayView = prevDayView;

      if (direction === 'back') {
        newDayView -= 1;

        if (newDayView < 0) {
          newDayView = listValidos.length - 1;
        }
      } else if (direction === 'today') {
        newDayView = 0;
      } else if (direction === 'forward') {
        newDayView += 1;
        if (newDayView > listValidos.length - 1) {
          newDayView = 0;
        }
      }

      // Asegurarse de que newDayView esté en el rango de 0 a 7
      // newDayView = (newDayView + 8) % 7;

      fetchData(newDayView);
      return newDayView; // Actualiza el estado del día con el nuevo valor
    });
  };

  if (fixedDate) {
    filtersValues.fecha = fixedDate;
    principalVlaues.fecha_carrusel = fixedDate;
  } else {
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);

    const twoDaysAgo = new Date(currentDate);
    twoDaysAgo.setDate(currentDate.getDate() - 1);
    const twoDaysAgoString = twoDaysAgo.toISOString().slice(0, 10);

    const fiveDaysLater = new Date(currentDate);
    fiveDaysLater.setDate(currentDate.getDate() + 365);
    const fiveDaysLaterString = fiveDaysLater.toISOString().slice(0, 10);

    const dates = [];
    const daysDifference = Math.ceil((fiveDaysLater.getTime() - twoDaysAgo.getTime()) / (1000 * 60 * 60 * 24));
    for (let i = 0; i < daysDifference; i++) {
      const date = new Date(twoDaysAgo);
      date.setDate(twoDaysAgo.getDate() + i);
      dates.push(date.toISOString().slice(0, 10));
    }



    if(listValidos.length > 0)
    {
      const fechasValidas = listValidos.map((item) => item.fecha);
      principalVlaues.fecha_carrusel = fechasValidas[dayView]
      
      // console.log("fechasValidas.length");
      // console.log(fechasValidas.length);
    }else{
      principalVlaues.fecha_carrusel = dates[dayView]
    }
  }

  const year = parseInt(principalVlaues.fecha_carrusel.substring(0, 4));
  const month = parseInt(principalVlaues.fecha_carrusel.substring(5, 7));
  const day = parseInt(principalVlaues.fecha_carrusel.substring(8, 10));

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');

 

  var modulo  = '';
  if(!inHome)
  {
    modulo  = 'Anirversarios';
  }

  useEffect(() => {
    props.setLoading(true);

    if(!listEstado){
      
      axios.get(ProjectConfig.api_url,
        {
          params: {
            request: 'aniversarios_list_estado',
          }
        })
        .then((response) => {
          setListEstado(response.data.accion)
          setListValidos(response.data.list)
          console.log("response")
          console.log(response.data.accion)
  
        })
        .catch((err) => {
          console.log(err.data);
        })
     
      

    }else{
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'aniversarios_list2',
          filters: filtersValues,
          principal: principalVlaues,
          page: page,
          full_list: inHome,
          user_id: user_id,
          modulo: modulo
        }
      })
      .then((response) => {
        console.log(response.data);
        setList(response.data.list)
        setCumple(response.data.cumple)
        setOrden(response.data.orden)
        setProfe(response.data.profe)
        setFalle(response.data.falle)
        setList_name(response.data.list_name)
        setTotalPages(response.data.total_pages)
        setMes(response.data.mes_actual)
        setDia(response.data.dia_actual)
        setLoading(true)
        props.setLoading(false);

      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())
    }

  }, [page, filtersValues, fixedDate, listEstado])
  /*=========================================================*/
  const currentYear = new Date().getFullYear();
  const mesesDelAnio = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE"
  ];

  let isFirstOnomastica = true;

  // console.log("list_name")
  // console.log(filtersValues)
  return (
    <div id='Aniversarios' className='module_container'>

      {!inHome &&
        <>
          <div className="module_topBar">
            <TopBar title='Aniversarios' filters={filters} setFilters={setFilters} setLoading={props.setLoading} />
          </div>
          <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />
        </>
      }

      {(inHome && list.length > 0) &&
        <>
          <h2 >Aniversarios</h2>
          {
            cumple.length > 0 && (
              <p style={{ marginLeft: '20px', color: '#BF7A1C', marginBottom: '0px' }}>CUMPLEAÑOS</p>
            )
          }
          {cumple.map((list_item, key) => (
            <Link className='CardList' style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}} key={key} to={'/aniversarios/' + list_item.aniversario_id}
              onClick={() => { props.setLoading(true) }}>
              <div className='CardListLeft'>
                <img className='ListImage' src={list_item.image} />
              </div>
              <div className='CardListRight'>
                <span className='CardListTitle'>{list_item.name}</span>
                <span className='CardListSubTitle'>
                  <span>{list_item.date_coincidence}  </span>
                </span>
              </div>
            </Link>
          ))}


          {list.map((list_item2, key) => (
            list_item2.fecha_santoral !== null && (
              <div key={key}>
                {isFirstOnomastica && (
                  <p style={{ marginLeft: '20px', color: '#BF7A1C', marginBottom: '0px' }}>
                    Onomástica
                  </p>
                )}
                <Link className='CardList' style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}} to={'/aniversarios/' + list_item2.aniversario_id} onClick={() => { props.setLoading(true) }}>
                  <div className='CardListLeft'>
                    <img className='ListImage' src={list_item2.image} />
                  </div>
                  <div className='CardListRight'>
                    <span className='CardListTitle'>{list_item2.name}</span>
                    <span className='CardListSubTitle'>
                      <span>{list_item2.date_coincidence}</span>
                    </span>
                  </div>
                </Link>
                {isFirstOnomastica && (isFirstOnomastica = false)} {/* Actualizar isFirstOnomastica después de crear la etiqueta <p> */}
              </div>
            )
          ))}
          {
            profe.length > 0 && (
              <p style={{ marginLeft: '20px', color: '#BF7A1C', marginBottom: '0px' }}>PROFESIÓN</p>
            )
          }
          {profe.map((list_item, key) => (
            <Link className='CardList' style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}} key={key} to={'/aniversarios/' + list_item.aniversario_id}
              onClick={() => { props.setLoading(true) }}>
              <div className='CardListLeft'>
                <img className='ListImage' src={list_item.image} />
              </div>
              <div className='CardListRight'>
                <span className='CardListTitle'>{list_item.name}</span>
                <span className='CardListSubTitle'>
                  <span>{list_item.date_coincidence}  </span>
                </span>
              </div>
            </Link>
          ))}
          {
            orden.length > 0 && (
              <p style={{ marginLeft: '20px', color: '#BF7A1C', marginBottom: '0px' }}>ORDENACIÓN</p>
            )
          }
          {orden.map((list_item, key) => (
            <Link className='CardList' style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}} key={key} to={'/aniversarios/' + list_item.aniversario_id}
              onClick={() => { props.setLoading(true) }}>
              <div className='CardListLeft'>
                <img className='ListImage' src={list_item.image} />
              </div>
              <div className='CardListRight'>
                <span className='CardListTitle'>{list_item.name}</span>
                <span className='CardListSubTitle'>
                  <span>{list_item.date_coincidence}  </span>
                </span>
              </div>
            </Link>
          ))}
          {
            falle.length > 0 && (
              <p style={{ marginLeft: '20px', color: '#BF7A1C', marginBottom: '0px' }}>DEFUNCIÓN</p>
            )
          }
          {falle.map((list_item, key) => (
            <Link className='CardList' style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}} key={key} to={'/aniversarios/' + list_item.aniversario_id}
              onClick={() => { props.setLoading(true) }}>
              <div className='CardListLeft'>
                <img className='ListImage' src={list_item.image} />
              </div>
              <div className='CardListRight'>
                <span className='CardListTitle'>{list_item.name}</span>
                <span className='CardListSubTitle'>
                  <span>{list_item.date_coincidence}  </span>
                </span>
              </div>
            </Link>
          ))}




        </>
      }

      {(!inHome && (list.length > 0 || list_name.length >0 )) &&

        <div className='module_content'>
          <div id="task_list_container" className="flex_column">

            {!loading && <div id='loader'><CircularProgress /></div>}
            {(!inHome && Object.keys(filtersValues).length === 0) &&
              <div className="carousel-title">
                <h1 style={{ textAlign: 'center', fontSize: '1.6em', color: '#6e5002' }}> <strong>{mesesDelAnio[month - 1]} {day} DE {currentYear}</strong></h1>
              </div>
            }

            {list.map((list_item, key) => (
              <Link className='CardList' key={key} to={'/aniversarios/' + list_item.aniversario_id}
                onClick={() => { props.setLoading(true) }}>
                <div className='CardListLeft'>
                  <img className='ListImage' src={list_item.image} />
                </div>

                <div className='CardListRight'>
                  <span className='CardListTitle'>{list_item.name}</span>
                  <span className='CardListSubTitle'>
                    <span>{list_item.date_coincidence}  </span>
                  </span>
                </div>
              </Link>
            ))}
            {list_name.map((list_item, key) => (
            <Link className='CardList' style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '5px'}} key={key} to={'/aniversarios/' + list_item.aniversario_id}
              onClick={() => { props.setLoading(true) }}>
              <div className='CardListLeft'>
                <img className='ListImage' src={list_item.image} />
              </div>
              <div className='CardListRight'>
                <span className='CardListTitle'>{list_item.name}</span>
                <span className='CardListSubTitle'>
                  <span>{list_item.date_coincidence}  </span>
                </span>
              </div>
            </Link>
          ))}

            {(!inHome && list_name.length == 0)  &&

              <div className="nav-buttons">
                <Button variant="outlined" onClick={() => navigate('back')}> <strong>Atrás</strong></Button>
                <Button variant="outlined" onClick={() => navigate('today')}> <strong>Hoy</strong> </Button>
                <Button variant="outlined" onClick={() => navigate('forward')}> <strong>Siguiente</strong> </Button>
              </div>
            }

          </div>
        </div>
      }
      {(!inHome && (list.length == 0 && list_name.length  == 0 )) &&
        <>
          <div className="carousel-title">
            <h1 style={{ textAlign: 'center', fontSize: '1.6em', color: '#6e5002' }}> <strong>{mesesDelAnio[month - 1]} {day} DE {currentYear}</strong></h1>
          </div>
          <div className="nav-buttons">
            <Button variant="outlined" onClick={() => navigate('back')}> <strong>Atrás</strong></Button>
            <Button variant="outlined" onClick={() => navigate('today')}> <strong>Hoy</strong> </Button>
            <Button variant="outlined" onClick={() => navigate('forward')}> <strong>Siguiente</strong> </Button>
          </div></>

      }
    </div>
  );
};