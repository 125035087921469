import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';



import {BrowserRouter, BrowserRouter as Router , useLocation, useSearchParams} from 'react-router-dom'


import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack';
import AppRoutes from 'AppRoutes';
import Login from 'components/Login/Login';
import Cookies from 'universal-cookie';
import DrawerMenu from 'elements/DrawerMenu';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarBar from 'elements/CalendarBar/CalendarBar';


const cookies = new Cookies();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    fontSize: 13, 
    fontWeightLight: 400,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#BF7A1C',
      main: '#BF7A1C',
      dark: '#BF7A1C',
      contrastText: '#fff',
    },
    secondary: { 
      light: '#048D81',
      main: '#707070',
      dark: '#c50000',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
});


  const Site = () => {


  /*=======================================
    TRY TO GET TOKEN APP FROM URL
  =======================================*/
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenApp = searchParams.get("tokenApp")
  if(tokenApp){
    localStorage.setItem('tokenApp', tokenApp);
  }
  /*=======================================
  =======================================*/


  const userIsLogged = cookies.get('ltkn') !== undefined;
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const isHome =location.pathname === "/"? "home" : "";
  
  const today: any = new Date();
  const todayFormated = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  // localStorage.setItem('fecha_view', todayFormated);
  const [fechaView, setFechaView] = useState(todayFormated);

  // console.log("Fecha Inicio: ")
  // console.log(fechaView)

    return (

    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3}>
        <div id='loader' className={!loading? 'loaderHide' : ''}>
          <CircularProgress />
        </div>
        {//  userIsLogged ? REDIRECT TO LOGIN
        !userIsLogged? (
          <Login setLoading={setLoading} />
          ) : (
          <>
            <div id='structure' className={isHome}>
              <div id="main-container"> 
              <CalendarBar setFechaView={setFechaView} />
                <div id="main-content">
                  <AppRoutes setLoading={setLoading} setFechaView={fechaView} />
                </div>
                
                <DrawerMenu setLoading={setLoading}  />

              </div>
            </div>
          </>
        )}
      </SnackbarProvider>
    </ThemeProvider>
    );
  
}

// ========================================
 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Site />
    </BrowserRouter>
  </React.StrictMode>
);







