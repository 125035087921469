import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";



export default function CelebracionesViewer (props:any) {

  
  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let {celebracion_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    celebracion_id: celebracion_id? celebracion_id : "null",
    image: "",
    name: "",
    date: null,
    bioHTML: "",
    bioJSON: null
  };
  
  const [values, setValues] = useState(defaultValues)


  
  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    props.setLoading(true);
    if (celebracion_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "celebraciones_details",
            id: celebracion_id,
          },
        })
        .then((response) => {
          setValues(response.data);
          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/


  return (
      <div id="CelebracionesView" className="module_container">

        <div className="module_topBar">
          <TopBar title='Celebraciones'/>
          <Toolbar/>
        </div>

        <div className='module_content'>
          

          <div className="titleSubtitle_container">
            <h2>{values.title}</h2>
            <span>{values.date}</span>
          </div>
         

          <div id="HTMLContent">
            {/* parse html */}
            <div dangerouslySetInnerHTML={{ __html: values.contentHTML }} />
          </div>
          
        </div>

      </div>
    
  );
};


