
import TopBar from 'elements/TopBar/TopBar';
import DateBar from 'elements/DateBar';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';



export default function LiturgiaHorasCategories(props: any) {
  

    useEffect (() => {
        props.setLoading(false);
    }, [])


  return (
    <div id='LiturgiaHorasCategories' className='module_container'>
      
      <div className="module_topBar">
        <TopBar title='Liturgia de las horas'/>
      </div>
      
      <DateBar />
     

      
      <div className='module_content'>
        <div id="liturgiaHoras_categories" className="flex_column">
          

            <div className='CardList'>
              <Link to='/liturgia-horas/oficio-lectura' className='icon' onClick={() => props.setLoading(true)}> 
                <span className='CardListTitle'>Oficio de lectura</span>
              </Link>
            </div>

            <div className='CardList'>
              <Link to='/liturgia-horas/laudes' className='icon' onClick={() => props.setLoading(true)}> 
                <span className='CardListTitle'>Laudes</span>
              </Link>
            </div>

            <div className='CardList'>
              <Link to='/liturgia-horas/intermedias' className='icon' onClick={() => props.setLoading(true)}>
                <span className='CardListTitle'>Horas Intermedias</span>
              </Link>
            </div>
           
            <div className='CardList'>
              <Link to='/liturgia-horas/visperas' className='icon' onClick={() => props.setLoading(true)}>
                <span className='CardListTitle'>Vísperas</span>
              </Link>
            </div>

            <div className='CardList'>
              <Link to='/liturgia-horas/completas' className='icon' onClick={() => props.setLoading(true)}>
                <span className='CardListTitle'>Completas</span>
              </Link>
            </div>

            
        </div>
      </div>
    </div>
  );
};



