import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import App from "elements/HtmlEditor/App";


interface data_root {
    children: data_json[];
    direction: string | null;
    format: string;
    indent: number;
    type: string;
    version: number;
  }
  
  interface data_json {
    root: data_root;
  }

interface Props {
  titulo: string;
  contenido: data_json;
}

export default function MisaAccordion(props: Props) {
  const { titulo, contenido } = props;

  const accordionData = [
    {
      titulo: titulo,
      contenido: contenido,
    }
    // Agrega más elementos si es necesario
  ];

  return (
    <div>
      {accordionData.map((item, index) => (
        <Accordion key={index} style={{  backgroundColor: '#FEF5E7', fontSize: '20px'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography> <strong>{item.titulo}</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>

            <App
              handleEditorChange={()=>{}}
              content={contenido}
              editable={true}
            />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
