import {Button, IconButton, TextareaAutosize, TextField} from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {ProjectConfig} from 'Global';
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import DownloadLink from "react-download-link";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function MultimediaViewer(props:any) {

  
  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let {multimedia_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    multimedia_id: multimedia_id? multimedia_id : "null",
    image: "",
    name: "",
    date: null,
    bioHTML: "",
    bioJSON: null,
    icono: "",
    extension: "",
  };
  
  const [values, setValues] = useState(defaultValues)


  
  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    props.setLoading(true);
    if (multimedia_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "multimedia_details",
            id: multimedia_id,
          },
        })
        .then((response) => {
          setValues(response.data);
          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  function download(fileUrl: any, fileName: any) {
    //add extennsion to file name
    fileName = fileName + fileUrl.substring(fileUrl.lastIndexOf("."));
    // DOWNLOAD REMOTE FILE from url
    axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
    );

  }

  return (
      <div id="MultimediaView" className="module_container">

        <div className="module_topBar">
          <TopBar title='Multimedia'/>
          <Toolbar/>
        </div>

       

        <div className='module_content'>

          <div id='multimedia_content'>
            {/* IS A YOUTUBE VIDEO */}
            {(values.isVideo || values.icono == "video") && (
              <iframe
                width="100%"
                //HEIGHT same as screen width
                height={window.innerWidth * 0.7}
                max-height={window.innerHeight}
                src={values.file}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {/* if type = FILE: onclick download */}
            {values.extension  == "docx" && (
              <Button
                color="primary"
                sx={{ padding:0, margin:0, width:'100%', height:'100%' }}
                id="downloadButton"
                onClick={() => download(values.file, values.title)}
                startIcon={<CloudDownloadIcon />}
              >
                Click para Descargar
              </Button>
              
            )}
            {/* if type is audio */}
            {values.type == "audio" && (
              <div className="audioContainer">
                <audio controls autoPlay>
                  <source src={values.file} type="audio/ogg" />
                  <source src={values.file} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )}
            {values.icono == "img" && (
              <div id='mv_image' style={{padding: 20}}>
              <img src={values.file} />
            </div>
            )}

            {values.extension === 'pdf' && (
              <embed src={values.file} width="100%" height="500" />
            )}





          </div>
          

          <div id='multimedia_texts'>
            <h2>{values.title}</h2>
            <p>{values.description}</p>
          </div>
          
        </div>

      </div>
    
  );
};


