import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography} from '@mui/material';
import logo from '../../assets/img/logo_vertical.png';
import Cookies from 'universal-cookie';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect } from 'react';
const cookies = new Cookies();

export default withSnackbar(
  function SignIn(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [loading, setLoading] = React.useState(false);

  useEffect (() => {
    props.setLoading(false);
  }, []);
    
  //IF TOKEN MOBILE APP EXISTS IN URL 
  const tokenApp = localStorage.getItem('tokenApp')? localStorage.getItem('tokenApp') : '';

  /*================================================
  SIGN IN (SUBMIT)
  ================================================*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "sign_in",
            email: data.get('email'),
            password: data.get('password'),
            tokenApp: tokenApp
          },
        })
        .then((response) => {
          console.log(response.data);
          if(response.data.status === "success"){
            
            enqueueSnackbar(response.data.message, { variant: 'success' });
            const user_data = response.data.user_data;
            console.log(user_data);

            /*================================================
            SET COOKIES
            ================================================*/
            cookies.set('ltkn', user_data.ltkn, { path: '/', maxAge: (86400 * 365) });
            cookies.set('user_id', user_data.user_id, { path: '/', maxAge: (86400 * 365) });
            cookies.set('user_name', user_data.user_name, { path: '/', maxAge: (86400 * 365) });
            cookies.set('role_id', user_data.role_id, { path: '/', maxAge: (86400 * 365) });
            /*================================================
            ================================================*/

            localStorage.setItem('user_id', user_data.user_id);
            localStorage.setItem('user_name', user_data.user_name);
            localStorage.setItem('user_image', user_data.image);
            localStorage.setItem('role_id', user_data.role_id);


            window.location.reload();
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            
          }
          setLoading(false);
         
        })
        .catch((err) => {
          console.log(err.data);
        });

    
  };
  /*================================================
  ================================================*/

  return (
    <div className='loginContainer'>
      <Box id='loginBox'>
        <img className='loginLogo' src={logo}></img>

        <p>Ingresa tu Correo y Contraseña para acceder.</p>
        <form onSubmit={handleSubmit} className='form'>
          <TextField
            required
            fullWidth
            id="email"
            label="Correo"
            name="email"
            type="text"
            autoComplete="email"
            autoFocus
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <LoadingButton
            size="small"
            type="submit"
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ fontSize: '13px', padding: '10px 0px'}}
          >
            Inciar Sesión
          </LoadingButton>

          <Link href="#" variant="body2">
            Olvide mi clave
          </Link>
        </form>
      </Box>
    </div>
  );
}
);