import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicAccordion from "./BasicAccordion";
import BasicAccordionCheck from "./BasicAccordionCheck";
import Cookies from "universal-cookie";
import CheckList from "./CheckList";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material";
import axios from "axios";

import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core";
import BasicAccordionPdf from "./BasicAccordionPdf";

interface SubCategoria {
  id: number;
  padre_id: number;
  label: string;
  titulo: string;
  tipo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  estado: string;
  delete: number;
  isChecked: boolean;
}

interface Categorias {
  id: number;
  padre_id: number;
  label: string;
  titulo: string;
  tipo: string;
  contentJSON: any | null;
  contentJSON_send: any | null;
  contentHTML: string;
  contentHTML_send: string;
  estado: string;
  isChecked: boolean;
  subCategorias: SubCategoria[];
}

interface List {
  acuerdo_id: number;
  title: string;
  contentHTML: string;
  isChecked: boolean;
}

interface pdfData {
  file_id: any;
  file: string;
  name: string;
  estado: string;
}

interface CheckUlt {
  id: number;
  modelo_id: number;
  ultimo_id: number;
}

interface Props {
  categoryName: string;
  categorias: Categorias[];
  lists: List[];
  model: string;
  isMobile: boolean;
  filesPdf: pdfData[];
  CheckUlt: CheckUlt;
}

export default function BasicAccordionAcu(props: Props) {
  const {
    categoryName,
    categorias,
    lists,
    model,
    isMobile,
    filesPdf,
    CheckUlt,
  } = props;
  const cookies = new Cookies();
  const user_id = cookies.get("user_id");

  const [openAccordion, setOpenAccordion] = useState(false);
  const [openInicio, setOpenInicio] = useState(false);

  useEffect(() => {
    
    // console.log("Categoria");
    // console.log(categorias);
    if (CheckUlt) {
      categorias.map((categoria, key) => {
        if (CheckUlt.modelo_id === categoria.id) {
          setOpenAccordion(true);
          setOpenInicio(true);
        }

        categoria.subCategorias.map((subcategoria, key) => {
          if (subcategoria.id == CheckUlt.modelo_id) {
            setOpenAccordion(true);
            setOpenInicio(true);
          }
        });
      });

      lists.map((list, index) => {
        if (CheckUlt.modelo_id === list.acuerdo_id) {
          setOpenAccordion(true);
          setOpenInicio(true);
        }
      });

      if(categorias.length > 0 || lists.length > 0){
        setOpenInicio(true);
      }
      // setOpenInicio(true);
      // 
    }
  }, [categorias, lists, CheckUlt.modelo_id]);

  const accordionData = [
    {
      categoryName: categoryName,
      categorias: categorias,
      lists: lists,
      isMobile: isMobile,
      filesPdf: filesPdf,
    },
  ];
  function download(fileUrl: any, fileName: any) {
    //add extennsion to file name
    fileName = fileName;
    // DOWNLOAD REMOTE FILE from url
    axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  console.log("openInicio");
  console.log(openInicio);
  return (
    <div>
      {openInicio && (
        <>
          {accordionData.map((item, index) => (
            <Accordion
              key={index}
              defaultExpanded={openAccordion}
              style={{ backgroundColor: "#FEF5E7", fontSize: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography>
                  {" "}
                  <strong>{item.categoryName}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.categorias.map((categoria, index100) => (
                  <div key={index100}>
                    <div key={index100}>
                      <BasicAccordionCheck
                        categoria={categoria}
                        user_id={user_id}
                        model={model}
                        CheckUlt={CheckUlt}
                      />
                    </div>
                  </div>
                ))}

                {item.lists.map((list_item, index4) => (
                  <div key={index4}>
                    <CheckList
                      titulo={list_item.title}
                      contenido={list_item.contentHTML}
                      isChecked={list_item.isChecked}
                      id={list_item.acuerdo_id}
                      user_id={user_id}
                      model={model} CheckUlt={CheckUlt}                      
                    />
                  </div>
                ))}

                {filesPdf.map((file, index5) => (
                  <div key={index5}>
                    <BasicAccordionPdf
                      titulo={file.name}
                      filePdf={file.file}
                    ></BasicAccordionPdf>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
}
