import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import BasicAccordionCon from 'elements/BasicAccordionCon';
import Cookies from 'universal-cookie';

export default function ConstitucionesViewer(props: any) {

  /*=======================================
    GET IDS FROM URL
  =======================================*/
  let { constitucion_id } = useParams();
  /*=======================================
  =======================================*/


  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    { type: "text", label: "Título", name: "title", value: "" },
  ]
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/
  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);

  const user_id = cookies.get('user_id');

  const currentPage = parseInt(localStorage.getItem('page') || "1");




  const [categoryName, setCategoryName] = useState('');

   interface SubCategoria {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    tipo: string;
    contentJSON: any | null; 
    contentJSON_send: any | null; 
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    delete: number;
    isChecked: boolean;
  }

  interface Categorias {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    tipo: string;
    contentJSON: any | null; 
    contentJSON_send: any | null; 
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    subCategorias: SubCategoria[];
    isChecked: boolean;
  }
  interface List {
    constitucion_id: number;
    title: string;
    contentHTML: string;
    isChecked: boolean;
  }

  interface Elementos {
    list: List[];
    categorias: Categorias[]
  }

  const [elementos, setElementos] = useState<Elementos[]>([]);
  const [categorias, setCategorias] = useState<Categorias[]>([]);
  interface pdfData {
    file_id: any;
    file: string;
    name: string;
    estado: string;
  }

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  interface CheckUltInt {
    id: number;
    modelo_id: number;
    ultimo_id: number;
  }

  const [checkUlt, setCheckUlt] = useState<CheckUltInt>({
    id: 0,
    modelo_id: 0,
    ultimo_id: 0,
  });

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'constituciones_list',
          constituciones_category_id: constitucion_id,
          filters: filtersValues,
          full_list: true,
          user_id: user_id
        }
      })
      .then((response) => {
        // setList(response.data.list)
        setCategoryName(response.data.category_name)

          setList(response.data.list)
          setCategorias(response.data.categorias)
          setFilesPdf(response.data.files);
          setCheckUlt(response.data.CheckUlt);

        props.setLoading(false);
        

      })
      .catch((err) => {
        console.log(err.data);
      })

  }, [elementos]);
  /*=========================================================*/
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Verificar si el dispositivo es móvil o tablet
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    function handleDeviceChange(e: any) {
      setIsMobile(e.matches);
    }

    // Registrar el oyente para cambios en la consulta de medios
    mediaQuery.addEventListener('change', handleDeviceChange);

    // Establecer el estado inicial
    handleDeviceChange(mediaQuery);

    // Desregistrar el oyente al desmontar el componente
    return () => {
      mediaQuery.removeEventListener('change', handleDeviceChange);
    };
  }, []);

  console.log("checkUlt")
  console.log(checkUlt)

  return (
    <div id='Constituciones' className='module_container'>

      <div className="module_topBar">
        <TopBar title={categoryName} filters={filters} setFilters={setFilters} />
      </div>


      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />




      <div className='module_content'>
        <div id="task_list_container" className="flex_column">

          {loading && <div id='loader'><CircularProgress /></div>}


          {/* {elementos.map((list_item, key) => ( */}
            <BasicAccordionCon tiuloPrincipal={categoryName} categorias={categorias} 
            list={list} model={'constituciones'} isMobile={isMobile} filesPdf={filesPdf} CheckUlt={checkUlt}  ></BasicAccordionCon>
          {/* ))}  */}




        </div>
      </div>
    </div>
  );
};



