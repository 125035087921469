import axios from "axios";
import { useEffect, useState } from "react";
import { CircularProgress, Pagination } from "@mui/material";
import { ProjectConfig } from "Global";
import TopBar from "elements/TopBar/TopBar";
import FiltersApplied from "elements/Filters/FiltersApplied";

import BasicAccordionDir from "elements/BasicAccordionDir";
import Cookies from "universal-cookie";
export default function DirectorioViewer(props: any) {
  /*================================================
  FILTERS
  =================================================*/
  const filters = [{ type: "text", label: "Título", name: "title", value: "" }];
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  };
  /*================================================= 
  ==================================================*/

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Verificar si el dispositivo es móvil o tablet
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    function handleDeviceChange(e: any) {
      setIsMobile(e.matches);
    }

    // Registrar el oyente para cambios en la consulta de medios
    mediaQuery.addEventListener("change", handleDeviceChange);

    // Establecer el estado inicial
    handleDeviceChange(mediaQuery);

    // Desregistrar el oyente al desmontar el componente
    return () => {
      mediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, []);

  interface SubCategoria {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    tipo: string;
    contentJSON: any | null;
    contentJSON_send: any | null;
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    delete: number;
    isChecked: boolean;
  }

  interface Categorias {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    tipo: string;
    contentJSON: any | null;
    contentJSON_send: any | null;
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    subCategorias: SubCategoria[];
    isChecked: boolean;
  }

  interface pdfData {
    file_id: any;
    file: string;
    name: string;
    estado: string;
  }

  // const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  interface Directo {
    directorio_id: number;
    title: string;
    contentHTML: string;
    categorias: Categorias[];
    files: pdfData[];
  }

  interface CheckUltInt {
    id: number;
    modelo_id: number;
    ultimo_id: number;
  }

  const [loading, setLoading] = useState(false);
  // const [list, setList] = useState<any []>([]);
  const [list, setList] = useState<Directo[]>([]);
  const [checkUlt, setCheckUlt] = useState<CheckUltInt>({
    id: 0,
    modelo_id: 0,
    ultimo_id: 0,
  });
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem("page") || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);

  const cookies = new Cookies();
  const user_id = cookies.get("user_id");
  const modulo = "Directorio";

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "directorio_list",
          page: page,
          filters: filtersValues,
          user_id: user_id,
          full_list: true,
          modulo: modulo,
          viewClient: true,
        },
      })
      .then((response) => {
        // setList(response.data.list)
        // console.log(response.data)
        response.data.list.map((categoriaNew: Directo, index1: number) =>
          // console.log(categoriaNew)
          setList((prevCategorias) => {
            // Copia el estado anterior y modifica la categoría en la posición index1
            const newCategorias = [...prevCategorias];
            newCategorias[index1] = categoriaNew;
            return newCategorias;
          })
        );
        setCheckUlt(response.data.CheckUlt);
        // console.log(response.data.CheckUlt)

        // setFilesPdf(response.data.list.files);
        setTotalPages(response.data.total_pages);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
      });
    localStorage.setItem("page", page.toString());
  }, [page, refreshList, filtersValues]);
  /*=========================================================*/

  // console.log(list)
  // console.log("data")
  // console.log(checkUlt)
  return (
    <div id="Directorio" className="module_container">
      <div className="module_topBar">
        <TopBar title="Directorio" filters={filters} setFilters={setFilters} />
      </div>

      <FiltersApplied
        filtersValues={filtersValues}
        setFiltersValues={setFiltersValues}
      />

      <div className="module_content">
        <div id="task_list_container" className="flex_column">
          {loading && (
            <div id="loader">
              <CircularProgress />
            </div>
          )}

          {list.map((list_item, key) => (
            <div className="CardContents" key={key}>
              <BasicAccordionDir
                titulo={list_item.title}
                contenido={list_item.contentHTML}
                categorias={list_item.categorias}
                model={"directorio"}
                CheckUlt={checkUlt}
                filesPdf={list_item.files}
                isMobile={isMobile}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

