import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectConfig } from 'Global';
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import App from "elements/HtmlEditor/App";
import BasicAccordion from "elements/BasicAccordion";
import Cookies from 'universal-cookie';

export default function LiturgiaHorasViewer(props: any) {


  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { category } = useParams();

  let category_title = "-"

  switch (category) {
    case 'oficio-lectura':
      category_title = "Oficio de lectura";
      break;
    case 'laudes':
      category_title = "Laudes";
      break;
    case 'intermedias':
      category_title = "Horas intermedias";
      break;
    case 'visperas':
      category_title = "Vísperas";
      break;
    case 'completas':
      category_title = "Completas";
      break;
  }

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');
  const modulo  = 'Lit ' + category_title;

  /*=======================================
  =======================================*/
  const [date, setDate] = useState(localStorage.getItem("date"));
  const [data, setData] = useState<any>();
  const [oracionInicial, setOracionInicial] = useState<any>();
  const [oracionFinal, setOracionFinal] = useState<any>();

  const getLectura = () => {
    if (data && data.lectura && typeof data.lectura === "object") {
      return true;
    }
    return false;
  }
  const getLecturas_info = () => {
    if (data && data.lecturas_info && typeof data.lecturas_info === "object") {
      return true;
    }
    return false;
  }
  const getReflexiones = () => {
    if (data && data.reflexiones && typeof data.reflexiones === "object") {
      return true;
    }
    return false;
  }
  const getLectio_divina = () => {
    if (data && data.lectio_divina && typeof data.lectio_divina === "object") {
      return true;
    }
    return false;
  }

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    props.setLoading(true);
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "liturgia_horas_dia",
          category: category,
          date: date,
          user_id: user_id,
          modulo: modulo
        },
      })
      .then((response) => {
        setData(response.data);
        setOracionInicial(response.data.oraciones_iniciales)
        setOracionFinal(response.data.oraciones_finales)
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, [date]);
  /*=======================================
  =======================================*/


  const accordionData = [
    {
      title: 'Accordion 1',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
      title: 'Accordion 2',
      content: 'Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    // Agrega más elementos si es necesario
  ];

  return (
    <div id="SantoralView" className="module_container">

      <div className="module_topBar">
        <TopBar title={category_title} />
        <Toolbar />
        {/* <DateBar onChange={setRomcalData}/> */}

        {data &&
          (
            <div id="liturgia_header" style={{
              backgroundColor: data.backgroundColor,
              color: data.textColor,
            }}>
              <span className="ld_category">{category_title.toUpperCase()}</span>
              <span className="ld_date">{data.fullDate}</span>
              <span className="ld_weekName">{data.weekName}</span>
              <div className="ld_colors">
                <span className="ld_color">{data.color}</span>
              </div>


            </div>
          )}
      </div>

      <div className='module_content'>

        {
          oracionInicial != null && (
            <>
              <BasicAccordion titulo={oracionInicial.titulo} contenido={oracionInicial.contentHTML } />
            </>
          )
        }

        {getLectura() && (
          <div id="misaLecturaBox">
            {/* parse html */}
            <App
              handleEditorChange={() => { }}
              content={data.lectura}
              editable={false}
            />
          </div>
        )}
        {/* {getLecturas_info() && (
          <div id="misaLecturaBox">

            <App
              handleEditorChange={() => { }}
              content={data.lecturas_info}
              editable={false}
            />
          </div>
        )} */}
        {/* {getReflexiones() && (
          <div id="misaLecturaBox">
            <App
              handleEditorChange={() => { }}
              content={data.reflexiones}
              editable={false}
            />
          </div>
        )}
        {getLectio_divina() && (
          <div id="misaLecturaBox">
            <App
              handleEditorChange={() => { }}
              content={data.lectio_divina}
              editable={false}
            />
          </div>
        )} */}


        {
          oracionFinal != null && (
            <BasicAccordion titulo={oracionFinal.titulo} contenido={oracionFinal.contentHTML } />
          )
        }

      </div>

    </div>

  );
};

