import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {ProjectConfig} from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import Cookies from 'universal-cookie';


export default function OracionesList (props:any) {

  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    {type: "text", label: "Nombre", name: "title", value: ""},
  ]
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/


  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const cookies = new Cookies();
  const user_id = cookies.get('user_id');

  const modulo  = 'Oraciones';
  


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'oraciones_list',
          filters: filtersValues,
          page: page,
          user_id: user_id,
          modulo: modulo
        }
      })
        .then((response) => {
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          props.setLoading(false);
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, filtersValues])
  /*=========================================================*/


  return (
    <div id='Oraciones' className='module_container'>
      
      <div className="module_topBar">
        <TopBar title='Oraciones' filters={filters} setFilters={setFilters}/>
      </div>
      

      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues}/>
     

      
      <div className='module_content'>
        <div id="task_list_container" className="flex_column">

                  {list.map((list_item,key) => (

                      <Link className='CardList' key={key} to={`/oraciones/${list_item.oracion_id}`}
                      onClick={()=> {props.setLoading(true)}}>
                        <div className='CardListLeft'>
                          <img className='ListImage' src={list_item.image} />
                        </div>
                        
                        <div className='CardListRight'>
                          <span className='CardListTitle'>{list_item.title}</span>
                        </div>
                      </Link>
                  ))}

          
          {/* <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          /> */}
 
        </div>
      </div>
    </div>
  );
};



