import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {ProjectConfig} from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import Cookies from 'universal-cookie';


export default function MaximasList(props:any) {

  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    {type: "text", label: "Texto", name: "content", value: ""},
  ]
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/


  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');

  const modulo  = 'Maximas';

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'maximas_list',
          filters: filtersValues,
          page: page,
          user_id: user_id,
          modulo: modulo
        }
      })
        .then((response) => {
          console.log(response.data)
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          props.setLoading(false);
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList, filtersValues])
  /*=========================================================*/


  return (
    <div id='Maximas' className='module_container'>
      
      <div className="module_topBar">
        <TopBar title='Máximas' filters={filters} setFilters={setFilters}/>
      </div>
      

      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues}/>
     
      <div className='module_content'>
        <div id="task_list_container" className="flex_column">

          {list.map((list_item,key) => (
              <div className='CardTexts' key={key}>    
                  <span className='CardListSubText'>{list_item.content}</span>
              </div>
          ))}

          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
 
        </div>
      </div>
    </div>
  );
};



