import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import BasicAccordionAcu from 'elements/BasicAccordionAcu';
import Cookies from 'universal-cookie';


export default function AcuerdosViewer(props: any) {

  /*=======================================
    GET IDS FROM URL
  =======================================*/
  let { acuerdo_id } = useParams();
  /*=======================================
  =======================================*/


  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    { type: "text", label: "Título", name: "title", value: "" },
  ]
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/

  const [list, setList] = useState<any[]>([]);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);

  const [categoryName, setCategoryName] = useState('');
  const cookies = new Cookies();
  const user_id = cookies.get('user_id');

  interface SubCategoria {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    tipo: string;
    contentJSON: any | null;
    contentJSON_send: any | null;
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    delete: number;
    isChecked: boolean;

  }

  interface Categorias {
    id: number;
    padre_id: number;
    label: string;
    titulo: string;
    tipo: string;
    contentJSON: any | null;
    contentJSON_send: any | null;
    contentHTML: string;
    contentHTML_send: string;
    estado: string;
    subCategorias: SubCategoria[];
    isChecked: boolean;
  }
  interface List {
    constitucion_id: number;
    title: string;
    contentHTML: string;
    isChecked: boolean;
    filePdf: string
  }

  interface Elementos {
    list: List[];
    categorias: Categorias[]
  }

  const [elementos, setElementos] = useState<Elementos[]>([]);
  const [categorias, setCategorias] = useState<Categorias[]>([]);
  interface pdfData {
    file_id: any;
    file: string;
    name: string;
    estado: string;
  }

  interface CheckUltInt {
    id: number;
    modelo_id: number;
    ultimo_id: number;
  }

  const [checkUlt, setCheckUlt] = useState<CheckUltInt>({
    id: 0,
    modelo_id: 0,
    ultimo_id: 0,
  });

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);


  const modulo = 'Cuerdos';
  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'acuerdos_list',
          acuerdos_category_id: acuerdo_id,
          page: page,
          user_id: user_id,
          filters: filtersValues,
          full_list: true,
          modulo: modulo
        }
      })
      .then((response) => {
        console.log(response.data)
        setList(response.data.list)
        setCategorias(response.data.categorias)
        setCategoryName(response.data.category_name)
        setFilesPdf(response.data.files);

        setCheckUlt(response.data.CheckUlt);
        props.setLoading(false);

      })
      .catch((err) => {
        console.log(err.data);
      })
    localStorage.setItem('page', page.toString())

  }, [page, refreshList, filtersValues])
  /*=========================================================*/

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Verificar si el dispositivo es móvil o tablet
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    function handleDeviceChange(e: any) {
      setIsMobile(e.matches);
    }

    // Registrar el oyente para cambios en la consulta de medios
    mediaQuery.addEventListener('change', handleDeviceChange);


    // Establecer el estado inicial
    handleDeviceChange(mediaQuery);

    // Desregistrar el oyente al desmontar el componente
    return () => {
      mediaQuery.removeEventListener('change', handleDeviceChange);
    };
  }, []);



  return (
    <div id='Acuerdos' className='module_container'>

      <div className="module_topBar">
        <TopBar title={categoryName} filters={filters} setFilters={setFilters} />
      </div>


      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />



      <div className='module_content'>
        <div id="task_list_container" className="flex_column">

          <BasicAccordionAcu categoryName={categoryName} categorias={categorias}
            lists={list} model={'acuerdos'} isMobile={isMobile} filesPdf={filesPdf} CheckUlt={checkUlt} ></BasicAccordionAcu>

        </div>
      </div>
    </div>
  );
};



