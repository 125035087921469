import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ViwerPdf from "./ViwerPdf";

interface Props {
  titulo: string;
  filePdf: string;
}

export default function BasicAccordionPdf(props: Props) {
  const { titulo, filePdf } = props;

  const accordionData = [
    {
      titulo: titulo,
      filePdf: filePdf,
    },
  ];

  return (
    <div>
      {accordionData.map((item, index) => (
        <Accordion
          key={index}
          style={{
            backgroundColor: "#FEF5E7",
            fontSize: "20px",
            width: "100%",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>
              {" "}
              <strong>{item.titulo}</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {filePdf !== "" && (
                <div id="OracionesView" className="module_container">
                  <ViwerPdf fileUrl={filePdf}></ViwerPdf>
                </div>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
