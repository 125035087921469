import {
  AppBar,
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ReactComponent as IconNotificaciones } from "icons/ic_menu_notificaciones.svg";
import { ReactComponent as IconMenu } from "icons/ic_menu.svg";
import logo_inicio from "../assets/img/logo_inicio.png";
import CalendarBar from "./CalendarBar/CalendarBar";
import { Link, useLocation } from "react-router-dom";

export default function DrawerMenu(props: any) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  // const pathname = window.location.pathname;
  const today: any = new Date();
  const todayFormated =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  // localStorage.setItem('fecha_view', todayFormated);
  const [fechaView, setFechaView] = useState(todayFormated);

  const signOut = () => {
    localStorage.removeItem("ltkn");
    //remove cookies
    document.cookie = "ltkn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/";
  };

  const { pathname } = useLocation();

  const handleRedireccionar = () => {
    console.log("click", pathname);
    pathname !== "/" && props.setLoading(true);
  };

  return (
    <div>
      <AppBar
        position="static"
        sx={{ top: "auto", bottom: 0, backgroundColor: "var(--fondo1)" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* inicio / */}
          {/* 
        <Link to="/">
            <img id='logo_inicio' src={logo_inicio} alt="logo" />
        </Link> */}

          <Link
            to="/"
            onClick={() => {
              const year = new Date().getFullYear();
              const month = new Date().getMonth() + 1;
              const day = new Date().getDate();
              var fechaComoString = year + "-"+month+"-"+day
              localStorage.setItem("date", fechaComoString);
              window.location.href = "/";
            }}
          >
            <IconButton color="inherit">
              <img id="logo_inicio" src={logo_inicio} alt="logo" />
            </IconButton>
          </Link>

          <Link
            to="/notificaciones"
            onClick={() => {
              pathname !== "/notificaciones" && props.setLoading(true);
            }}
          >
            <IconButton color="inherit">
              <IconNotificaciones />
            </IconButton>
          </Link>
          <IconButton color="inherit" onClick={toggleDrawer(true)}>
            <IconMenu />
          </IconButton>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        id="drawerMenu"
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={true}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        //max height 80%
        sx={{
          height: "90%",
          "& .MuiDrawer-paper": {
            height: "90%",
            boxSizing: "border-box",
          },
        }}
      >
      

        <div className="listMenu" id="menu_newo">
          <Link
            to="/santoral"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/santoral" && props.setLoading(true);
            }}
          >
            <Button>Santoral</Button>
          </Link>

          <Link
            to="/liturgia-horas"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/liturgia-horas" && props.setLoading(true);
            }}
          >
            <Button>Liturgia de las horas</Button>
          </Link>

          <Link
            to="/misas"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/misas" && props.setLoading(true);
            }}
          >
            <Button>Misa del día</Button>
          </Link>

          <Link
            to="/celebraciones"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/celebraciones" && props.setLoading(true);
            }}
          >
            <Button>Celebraciones</Button>
          </Link>

          <Link
            to="/aniversarios"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/aniversarios" && props.setLoading(true);
            }}
          >
            <Button>Aniversarios</Button>
          </Link>

          <Link
            to="/ano-liturgico"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/ano-liturgico" && props.setLoading(true);
            }}
          >
            <Button>Año litúrgico</Button>
          </Link>

          <Link
            to="/oraciones"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/oraciones" && props.setLoading(true);
            }}
          >
            <Button>Oraciones</Button>
          </Link>

          <Link
            to="/multimedia"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/multimedia" && props.setLoading(true);
            }}
          >
            <Button>Multimedia</Button>
          </Link>

          <Link
            to="/maximas"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/maximas" && props.setLoading(true);
            }}
          >
            <Button>Máximas</Button>
          </Link>

          <Link
            to="/constituciones"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/constituciones" && props.setLoading(true);
            }}
          >
            <Button>Constituciones</Button>
          </Link>

          <Link
            to="/Directorio"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/Directorio" && props.setLoading(true);
            }}
          >
            <Button>Directorio</Button>
          </Link>

          <Link
            to="/acuerdos-capitulares"
            onClick={() => {
              setDrawerOpen(false);
              pathname !== "/acuerdos-capitulares" && props.setLoading(true);
            }}
          >
            <Button>Acuerdos capitulares</Button>
          </Link>

          <Button onClick={signOut}>Cerrar sesión</Button>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
