import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import BasicAccordion from "elements/BasicAccordion";


export default function FiestasViewer(props: any) {


  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { fiesta_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    fiesta_id: fiesta_id ? fiesta_id : "null",
    image: "",
    name: "",
    date: null,
    bioHTML: "",
    bioJSON: null
  };

  const [values, setValues] = useState(defaultValues)



  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    props.setLoading(true);
    if (fiesta_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "fiesta_details",
            id: fiesta_id,
          },
        })
        .then((response) => {
          console.log("response")
          console.log(response)
          setValues(response.data);
          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  var inicial_html = "<p class=\"PlaygroundEditorTheme__paragraph\"><span></span></p>";
  var inicial_html2 = "<p class=\"PlaygroundEditorTheme__paragraph\"><br></p>";


  function validar (dato:String)
  {
    if(dato !== null && dato !== '' && dato != inicial_html && dato != inicial_html2) return true;

    return false;
  }

  return (
    <div id="SantoralView" className="module_container">

      <div className="module_topBar" style={{ padding: 0 }}>
        <TopBar title='Fiesta' />
        <Toolbar />
      </div>

      <div className='module_contentSantoral'>
        <div id='mv_image' style={{ padding: 20 }}>
          <img src={values.image} />
      </div>



      <h2><strong>{values.titulo}</strong></h2>





        
          <>
            {( validar (values.bioHTML_primeras_visperas) ) && (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <BasicAccordion titulo={'PRIMERAS VÍSPERAS'} contenido={values.bioHTML_primeras_visperas} />
                </div>
              </>
            )}

            <div>
              {(validar (values.bioHTML_oficio_lectura)) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'OFICIO DE LECTURA'} contenido={values.bioHTML_oficio_lectura} />
                  </div>
                </div>
              )}

              {(validar (values.bioHTML_laudes)) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'LAUDES'} contenido={values.bioHTML_laudes} />
                  </div>
                </div>
              )}

              {(validar (values.bioHTML_horas_intermedias)) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'HORAS INTERMEDIAS'} contenido={values.bioHTML_horas_intermedias} />
                  </div>
                </div>
              )}

              {(validar (values.bioHTML_visperas)) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'VÍSPERAS'} contenido={values.bioHTML_visperas} />
                  </div>
                </div>
              )}

              {(validar (values.bioHTML_completas)) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'COMPLETAS'} contenido={values.bioHTML_completas} />
                  </div>
                </div>
              )}

              {(validar (values.bioHTML_lecturas)) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'LECTURAS'} contenido={values.bioHTML_lecturas} />
                  </div>
                </div>
              )}
            </div>

          </>
        

      </div>

    </div>

  );
};


