import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import BasicAccordion from "elements/BasicAccordion";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BasicAccordionPdf from 'elements/BasicAccordionPdf';

import { Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core';

export default function SantoralViewer(props: any) {


  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { santoral_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    santoral_id: santoral_id ? santoral_id : "null",
    image: "",
    name: "",
    date: null,
    bioHTML: "",
    bioJSON: null
  };

  const [values, setValues] = useState(defaultValues)
  interface pdfData {
    file_id: any;
    file: any;
    name: string;
    estado: string;
  }

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    props.setLoading(true);
    if (santoral_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "santoral_details",
            id: santoral_id,
          },
        })
        .then((response) => {
          // console.log("response")
          // console.log(response)
          setValues(response.data);
          props.setLoading(false);

          setFilesPdf(response.data.files)
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Verificar si el dispositivo es móvil o tablet
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    function handleDeviceChange(e: any) {
      setIsMobile(e.matches);
    }

    // Registrar el oyente para cambios en la consulta de medios
    mediaQuery.addEventListener('change', handleDeviceChange);

    // Establecer el estado inicial
    handleDeviceChange(mediaQuery);

    // Desregistrar el oyente al desmontar el componente
    return () => {
      mediaQuery.removeEventListener('change', handleDeviceChange);
    };
  }, []);

  function download(fileUrl: any, fileName: any) {
    //add extennsion to file name
    fileName = fileName;
    // DOWNLOAD REMOTE FILE from url
    axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
    );

  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  var inicial_html = "<p class=\"PlaygroundEditorTheme__paragraph\"><span></span></p>";
  return (
    <div id="SantoralView" className="module_container">

      <div className="module_topBar" style={{ padding: 0 }}>
        <TopBar title='Santoral' />
        <Toolbar />
      </div>

      <div className='module_contentSantoral'>
        <div id='mv_image' style={{ padding: 20 }}>
          <img src={values.image} />
        </div>


        {/* <h2>{values.name}, {values.categoria}. </h2> */}
        {values.categoria == null && (
          <h2><strong>{values.name}</strong></h2>
        )}
        {values.categoria != null && (
          <h2><strong>{values.name}, {values.categoria}.</strong></h2>
        )}

        {/* <div id="HTMLContent">
          <div dangerouslySetInnerHTML={{ __html: values.firstParagraph }} />
        </div> */}

        <br />
        {(values.bioHTML != "" && values.bioHTML != null && values.bioHTML != inicial_html) && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BasicAccordion titulo={'BIOGRAFÍA'} contenido={values.bioHTML} />
            </div>
          </>
        )}
        {(values.bioHTML_oraciones != "" && values.bioHTML_oraciones != null && values.bioHTML_oraciones != inicial_html) && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BasicAccordion titulo={'ORACIONES DEL SANTO'} contenido={values.bioHTML_oraciones} />
            </div>
          </>
        )}
        {(values.bioHTML_calendario != "" && values.bioHTML_calendario != null && values.bioHTML_calendario != inicial_html) && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BasicAccordion titulo={'CALENDARIO PROPIO HSF'} contenido={values.bioHTML_calendario} />
            </div>
          </>
        )}


        {/* <div id='mv_date'>
          <h3>Fecha del santo</h3>
          <span>{values.date}</span>
        </div>

        <h3>Biografía</h3>
        <div id="HTMLContent">
          <div dangerouslySetInnerHTML={{ __html: values.bioHTML }} />
        </div> */}



        {values.tienePropio === "1" && (
          <>
            {(values.bioHTML_primeras_visperas !== null && values.bioHTML_primeras_visperas != inicial_html) && (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <BasicAccordion titulo={'PRIMERAS VÍSPERAS'} contenido={values.bioHTML_primeras_visperas} />
                </div>
              </>
            )}

            <div>
              {(values.bioHTML_oficio_lectura !== null && values.bioHTML_primeras_visperas != inicial_html) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'OFICIO DE LECTURA'} contenido={values.bioHTML_oficio_lectura} />
                  </div>
                </div>
              )}

              {(values.bioHTML_laudes !== null && values.bioHTML_laudes != inicial_html) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'LAUDES'} contenido={values.bioHTML_laudes} />
                  </div>
                </div>
              )}

              {(values.bioHTML_horas_intermedias !== null && values.bioHTML_horas_intermedias != inicial_html) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'HORAS INTERMEDIAS'} contenido={values.bioHTML_horas_intermedias} />
                  </div>
                </div>
              )}

              {(values.bioHTML_visperas !== null && values.bioHTML_visperas != inicial_html) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'VÍSPERAS'} contenido={values.bioHTML_visperas} />
                  </div>
                </div>
              )}

              {(values.bioHTML_completas !== null && values.bioHTML_completas != inicial_html) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'COMPLETAS'} contenido={values.bioHTML_completas} />
                  </div>
                </div>
              )}

              {(values.bioHTML_lecturas !== null && values.bioHTML_lecturas != inicial_html) && (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <BasicAccordion titulo={'LECTURAS'} contenido={values.bioHTML_lecturas} />
                  </div>
                </div>
              )}
            </div>

          </>
        )}

{
        filesPdf.map((file,index) => (

          <BasicAccordionPdf titulo={file.name} filePdf={file.file}></BasicAccordionPdf>
        ))

      }

      </div>

    </div>

  );
};


