import axios from 'axios';
import { Fragment, useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {ProjectConfig} from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import DateBar from 'elements/DateBar';
import Cookies from 'universal-cookie';


export default function CalendarioList (props:any) {

  /*=======================================
    GET IDS FROM URL
  =======================================*/
  let {date} = useParams();
  date && localStorage.setItem('date', date);
  /*=======================================
  =======================================*/
  const [santoralList, setSantoralList] = useState<any []>([]);
  const [aniversariosList, setAniversariosList] = useState<any []>([]);
  const [celebracionesList, setCelebracionesList] = useState<any []>([]);
  const [maxima, setMaxima] = useState("");
  const [dateFormatted, setDateFormatted] = useState("");

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');
  const modulo  = 'Calendario';

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'calendario_list',
          date: date,
          modulo: modulo,
          user_id:user_id
        }
      })
        .then((response) => {
          setSantoralList(response.data.santoral.list)
          setAniversariosList(response.data.aniversarios.list)
          setCelebracionesList(response.data.celebraciones.list)
          setMaxima(response.data.maxima)
          setDateFormatted(response.data.dateFormated)
          props.setLoading(false);
        }) 
        .catch((err) => {
          console.log(err.data);
        })
    
  }, [date])
  /*=========================================================*/

  
  return (
    <div id='Calendario' className='module_container'>
      
      
      <div className="module_topBar">
        <TopBar title={dateFormatted} />
      </div>

      <DateBar hidden date={date}/>
      
      <div className='module_content'>
        <div id="task_list_container" className="flex_column">


          {/* SANTORAL */}
          {santoralList.length > 0 &&
          (
            <Fragment>
              <h3>Santos</h3>
              <div className="list_container">
              {santoralList.map((list_item,key) => (

                <Link className='CardList' key={key} to={`/santoral/${list_item.santoral_id}`} style={{ backgroundColor: list_item.color_claro }}
                onClick={()=> {props.setLoading(true)}}>
                  <div className='CardListLeft'>
                    <img className='ListImage' src={list_item.image} />
                  </div>
                  
                  <div className='CardListRight' >
                  {list_item.categoria == null && (
                    <span className='CardListTitle'> <strong>{list_item.name} </strong></span>
                  )}
                  {list_item.categoria != null && (
                    <span className='CardListTitle'> <strong>{list_item.name}, {list_item.categoria}. </strong></span>
                  )}
                    <span className='CardListSubTitle'><strong>{list_item.month} / {list_item.day}</strong></span>
                  </div>
                </Link>
                ))}
              </div>
          </Fragment>
          )}

          {/* LITURGIA DE LAS HORAS */}
          <h3>Liturgia de las Horas</h3>

          <div id="liturgiaHoras_categories" className="flex_column">
          

            <Link className='CardList' to={`/liturgia-horas/oficio-lectura`} 
            onClick={()=> {props.setLoading(true)}} > 
                <span className='CardListTitle'>Oficio de lectura</span>
            </Link>

            <Link className='CardList' to={`/liturgia-horas/laudes`}
            onClick={()=> {props.setLoading(true)}} >
                <span className='CardListTitle'>Laudes</span>
            </Link>

            <Link className='CardList' to={`/liturgia-horas/intermedias`}
            onClick={()=> {props.setLoading(true)}} >
                <span className='CardListTitle'>Horas Intermedias</span>
            </Link>

            <Link className='CardList' to={`/liturgia-horas/visperas`}
            onClick={()=> {props.setLoading(true)}} >
                <span className='CardListTitle'>Vísperas</span>
            </Link>

            <Link className='CardList' to={`/liturgia-horas/completas`}
            onClick={()=> {props.setLoading(true)}} >
                <span className='CardListTitle'>Completas</span>
            </Link>

          </div>
          


          {/* MISAS */}
          <h3>Misa del día</h3>
          <Link id='misa_dia' className='CardList' to={`/misas`}
          onClick={()=> {props.setLoading(true)}} >
              <span className='CardListTitle'>IR A LECTURAS</span>
          </Link>
          

          {/* ANIVERSARIOS */}
          {aniversariosList.length > 0 &&
          (
            <Fragment>
              <h3>Aniversarios</h3>
              <div className="flex_column">
                {aniversariosList.map((item: any, key: any) => (
                  <Link className='CardList' key={key} to={`/aniversarios/${item.aniversario_id}`}
                  onClick={()=> {props.setLoading(true)}} > 
                    <div className='CardListLeft'>
                      <img className='ListImage' src={item.image} />
                    </div>
                    
                    <div className='CardListRight'>
                      <span className='CardListTitle'>{item.name}</span>
                      <span className='CardListSubTitle'>
                        
                        { <span className='date_coincidence'>{item.date_coincidence}</span>}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </Fragment>
          )}


          {/* CELEBRACIONES */}
          {celebracionesList.length > 0 &&
          (
            <Fragment>
              <h3>Celebraciones</h3>
              <div id='celebraciones_list_container' className="flex_column">
              {celebracionesList.map((list_item,key) => (

                <Link className='CardList' key={key} to={`/celebraciones/${list_item.celebracion_id}`}
                onClick={()=> {props.setLoading(true)}} >    
                  
                  <div className='CardListRight'>
                    <span className='CardListTitle'>{list_item.title}</span>
                    <span className='CardListSubTitle'>{list_item.date}</span>
                  </div>
                </Link>
                ))}
              </div>
            </Fragment>
          )}


          {/* MÁXIMA */}
          <h3>Máximas</h3>
          <div className="maxima_container">
            
            <div className='CardTexts'>    
                <span className='CardListSubText'>{maxima}</span>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
};



