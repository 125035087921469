
import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom';


import ic_santoral from 'icons/ic_santoral.svg';
import ic_liturgia_horas from 'icons/ic_liturgia_horas.svg';
import ic_misas from 'icons/ic_misas.svg';
import ic_celebraciones from 'icons/ic_celebraciones.svg';
import ic_aniversarios from 'icons/ic_aniversarios.svg';
import ic_ano_liturgico from 'icons/ic_ano_liturgico.svg';
import ic_oraciones from 'icons/ic_oraciones.svg';
import ic_multimedia from 'icons/ic_multimedia.svg';
import ic_maximas from 'icons/ic_maximas.svg';
import ic_fiestasmovibles from 'icons/ic_fiestasmovibles.svg';

import ic_constituciones from 'icons/ic_constituciones.svg';
import ic_directorio from 'icons/ic_directorio.svg';
import ic_acuerdos_capitulares from 'icons/ic_acuerdos_capitulares.svg';
import SantoralList from 'components/Santoral/SantoralList';
import CalendarioList from 'components/Calendario/CalendarioList';
import AniversariosList from 'components/Aniversarios/AniversariosList';
import AnoLiturgicoViewer from 'components/AnoLiturgico/AnoLiturgicoViewer';
import AnoLiturgicoCategories from 'components/AnoLiturgico/AnoLiturgicoCategories';

export default function Home (props: any) {

    

  useEffect(() => {
    props.setLoading(false);
  }, []);
  
  //CURRENT DAY DATE AAAA-MM-DD TODAY
  const today = new Date();
  // const today_date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const [fechaView, setFechaView] = useState(localStorage.getItem('fecha_view') || '');
 
  // console.log("props.setFechaView")
  // console.log(props.setFechaView)
  
  return (
    <div id='Home' className='module_container'>
      <div className="module_topBar">
        
      </div>
      
      <SantoralList setLoading={props.setLoading} inHome={true} fixedDate={props.setFechaView}/>
      
      <div className='module_content'>
        <div id='iconsContainer'>
            
            <Link to={`/liturgia-horas?user_id=10`} className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_liturgia_horas" className='iconImage'>
                <img src={ic_liturgia_horas} alt='liturgia-horas' />
              </div>
              <h3>Liturgia de las horas</h3>
            </Link>

             <Link to='/misas' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_misas"  className='iconImage'>
                <img src={ic_misas} alt='misas' />
              </div>
              <h3>Misa del día</h3>
            </Link>

            <Link to='/fiestas' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_fiestasmovibles"  className='iconImage'>
                <img src={ic_fiestasmovibles} alt='santoral' />
              </div>
              <h3>Propios</h3>
            </Link>
            <Link to='/santoral' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_santoral"  className='iconImage'>
                <img src={ic_santoral} alt='santoral' />
              </div>
              <h3>Santoral</h3>
            </Link>

            <Link
              to='/ano-liturgico'
              className='icon'
              onClick={() => {
                localStorage.setItem('date', "null");
                props.setLoading(true)
              }}
            >
              <div id="ic_ano_liturgico"  className='iconImage'>
                <img src={ic_ano_liturgico} alt='ano-liturgico' />
              </div>
              <h3>Calendario litúrgico</h3>
            </Link>

            <Link to='/oraciones' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_oraciones"  className='iconImage'>
                <img src={ic_oraciones} alt='oraciones' />
              </div>
              <h3>Oraciones</h3>
            </Link>

            <Link to='/celebraciones' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_celebraciones"  className='iconImage'>
                <img src={ic_celebraciones} alt='celebraciones' />
              </div>
              <h3>Celebraciones</h3>
            </Link>

            <Link to='/aniversarios' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_aniversarios"  className='iconImage'>
                <img src={ic_aniversarios} alt='aniversarios' />
              </div>
              <h3>Aniversarios</h3>
            </Link>

            <Link to='/maximas' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_maximas"  className='iconImage'>
                <img src={ic_maximas} alt='maximas' />
              </div>
              <h3>Máximas</h3>
            </Link>

            <Link to='/multimedia' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_multimedia"  className='iconImage'>
                <img src={ic_multimedia} alt='multimedia' />
              </div>
              <h3>Multimedia</h3>
            </Link>

            <Link to='/constituciones' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_constituciones"  className='iconImage'>
                <img src={ic_constituciones} alt='constituciones' />
              </div>
              <h3>Constituciones</h3>
            </Link>

            <Link to='/Directorio' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_directorio"  className='iconImage'>
                <img src={ic_directorio} alt='directorio' />
              </div>
              <h3>Directorio</h3>
            </Link>

            <Link to='/acuerdos-capitulares' className='icon' onClick={() => props.setLoading(true)}>
              <div id="ic_acuerdos_capitulares"  className='iconImage'>
                <img src={ic_acuerdos_capitulares} alt='acuerdos-capitulares' />
              </div>
              <h3>Acuerdos capitulares</h3>
            </Link> 
            <Link to='/acuerdos-capitulares' className='icon' onClick={() => props.setLoading(true)}>
            </Link> 
            <Link to='/acuerdos-capitulares' className='icon' onClick={() => props.setLoading(true)}>
            </Link> 
            <Link to='/acuerdos-capitulares' className='icon' onClick={() => props.setLoading(true)}>
            </Link> 
        </div>
        



        


      </div>

      <AniversariosList setLoading={props.setLoading} inHome={true} fixedDate={props.setFechaView}/>
    
        
    </div>
  );
};


