import AcuerdosCategories from 'components/Acuerdos/AcuerdosCategories';
import AcuerdosViewer from 'components/Acuerdos/AcuerdosViewer';
import AniversariosList from 'components/Aniversarios/AniversariosList';
import AniversariosViewer from 'components/Aniversarios/AniversariosViewer';
import AnoLiturgicoCategories from 'components/AnoLiturgico/AnoLiturgicoCategories';
import AnoLiturgicoViewer from 'components/AnoLiturgico/AnoLiturgicoViewer';
import CalendarioList from 'components/Calendario/CalendarioList';
import CelebracionesList from 'components/Celebraciones/CelebracionesList';
import CelebracionesViewer from 'components/Celebraciones/CelebracionesViewer';
import ConstitucionesCategories from 'components/Constituciones/ConstitucionesCategories';
import ConstitucionesViewer from 'components/Constituciones/ConstitucionesViewer';
import DirectorioViewer from 'components/Directorio/AcuerdosViewer';
import Home from 'components/Home/Home';

import FiestasList from 'components/Fiestas/FiestasList';
import FiestasViewer from 'components/Fiestas/FiestasViewer';
import LiturgiaHorasCategories from 'components/LiturgiaHoras/LiturgiaHorasCategories';
import LiturgiaHorasViewer from 'components/LiturgiaHoras/LiturgiaHorasViewer';
import MaximasList from 'components/Maximas/MaximasList';
import MisasViewer from 'components/Misas/MisasViewer';
import MultimediaList from 'components/Multimedia/MultimediaList';
import MultimediaViewer from 'components/Multimedia/MultimediaViewer';
import NotificacioneslList from 'components/Notificaciones/NotificacioneslList';
import NotificacionesViewer from 'components/Notificaciones/NotificacionesViewer';
import OracionesList from 'components/Oraciones/OracionesList';
import OracionesViewer from 'components/Oraciones/OracionesViewer';
import SantoralList from 'components/Santoral/SantoralList';
import SantoralViewer from 'components/Santoral/SantoralViewer';
import Tests from 'components/tests';
import {Route, Routes} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function AppRoutes(props:any) {
  

  return (
      <Routes>
        
      
        {/* HOME */}
        <Route path='/' element={<Home setLoading={props.setLoading} setFechaView={props.setFechaView}/>} />



        
        {/* SANTORAL */}
        <Route path='/santoral' element={<SantoralList setLoading={props.setLoading}/>} />
        <Route path='/santoral/:santoral_id' element={<SantoralViewer setLoading={props.setLoading}/>} />

        {/* LITURGIA DE LAS HORAS */}
        <Route path='/liturgia-horas' element={<LiturgiaHorasCategories setLoading={props.setLoading}/>} />
        <Route path='/liturgia-horas/:category/' element={<LiturgiaHorasViewer setLoading={props.setLoading}/>} />

        {/* MISAS */}
        <Route path="/misas" element={<MisasViewer setLoading={props.setLoading}/>} />



        
        
        {/* CELEBRACIONES */}
        <Route path='/celebraciones' element={<CelebracionesList setLoading={props.setLoading}/>} />
        <Route path='/celebraciones/:celebracion_id' element={<CelebracionesViewer setLoading={props.setLoading}/>} />
        
        {/* ANIVERSARIOS */}
        <Route path='/aniversarios' element={<AniversariosList setLoading={props.setLoading}/>} />
        <Route path='/aniversarios/:aniversario_id' element={<AniversariosViewer setLoading={props.setLoading}/>} />

        {/* AÑO LITURGICO */}
        <Route path='/ano-liturgico' element={<AnoLiturgicoCategories setLoading={props.setLoading}/>} />
        <Route path='/ano-liturgico/:category' element={<AnoLiturgicoViewer setLoading={props.setLoading}/>} />

       


        {/* ORACIONES */}
        <Route path='/oraciones' element={<OracionesList setLoading={props.setLoading}/>} />
        <Route path='/oraciones/:oracion_id' element={<OracionesViewer setLoading={props.setLoading}/>} />
        
        {/* MULTIMEDIA */}
        <Route path="/multimedia" element={<MultimediaList setLoading={props.setLoading}/>} />
        <Route path="/multimedia/:multimedia_id" element={<MultimediaViewer setLoading={props.setLoading}/>} />

         {/* MAXIMAS */}
         <Route path="/maximas" element={<MaximasList setLoading={props.setLoading}/>} />





        {/* CONSTITUCIONES */}
        <Route path="/constituciones" element={<ConstitucionesCategories setLoading={props.setLoading}/>} />
        <Route path="/constituciones/:constitucion_id" element={<ConstitucionesViewer setLoading={props.setLoading}/>} />
        
         {/* DIRECTORIO */}
         <Route path="/directorio" element={<DirectorioViewer setLoading={props.setLoading}/>} />

        {/* ACUERDOS CAPITULARES */}
        <Route path="/acuerdos-capitulares" element={<AcuerdosCategories setLoading={props.setLoading}/>} />
        <Route path="/acuerdos-capitulares/:acuerdo_id" element={<AcuerdosViewer setLoading={props.setLoading}/>} />

        {/* FIESTAS */}
        <Route path="/fiestas" element={<FiestasList setLoading={props.setLoading}/>} />
        <Route path="/fiestas/:fiesta_id" element={<FiestasViewer setLoading={props.setLoading}/>} />

       

        {/* NOTIFICACIONES */}
        <Route path="/notificaciones" element={<NotificacioneslList setLoading={props.setLoading}/>} />
        <Route path="/notificaciones/:notificacion_id" element={<NotificacionesViewer setLoading={props.setLoading}/>} />

        {/* CALENDARIO */}
        <Route path="/calendario/:date" element={<CalendarioList setLoading={props.setLoading}/>} />

         
        
      
        {/* TESTS */}
        <Route path="/tests" element={<Tests />}/>
        



        
      </Routes>
  );
}
