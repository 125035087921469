import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BasicAccordionPdf from "elements/BasicAccordionPdf";

export default function AniversariosViewer(props: any) {
  /*=======================================
    GET PROJECT ID AND ESTIMATE ID FROM URL
  =======================================*/
  let { aniversario_id } = useParams();
  /*=======================================
  =======================================*/

  const defaultValues: any = {
    aniversario_id: aniversario_id ? aniversario_id : "null",
    image: "",
    name: "",
    date: null,
    bioHTML: "",
    bioJSON: null,
  };

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(defaultValues);
  interface pdfData {
    file_id: any;
    file: any;
    name: string;
    estado: string;
  }

  const [filesPdf, setFilesPdf] = useState<pdfData[]>([]);

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    props.setLoading(true);
    if (aniversario_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "aniversarios_details",
            id: aniversario_id,
          },
        })
        .then((response) => {
          setValues(response.data);
          setFilesPdf(response.data.files);
          props.setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);
  /*=======================================
  =======================================*/

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Verificar si el dispositivo es móvil o tablet
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    function handleDeviceChange(e: any) {
      setIsMobile(e.matches);
    }

    // Registrar el oyente para cambios en la consulta de medios
    mediaQuery.addEventListener("change", handleDeviceChange);

    // Establecer el estado inicial
    handleDeviceChange(mediaQuery);

    // Desregistrar el oyente al desmontar el componente
    return () => {
      mediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, []);

  function download(fileUrl: any, fileName: any) {
    //add extennsion to file name
    fileName = fileName;
    // DOWNLOAD REMOTE FILE from url
    axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }
  return (
    <div id="AniversarioView" className="module_container">
      <div className="module_topBar">
        <TopBar title="Aniversario" />
        <Toolbar />
      </div>

      <div className="module_content">
        <div id="mv_image">
          <img src={values.image} />
        </div>
        <h2>{values.name}</h2>

        <div id="mv_date">
          <h3>Nacimiento</h3>
          <span>{values.birth_date}</span>
        </div>

        <div id="mv_date">
          <h3>Fallecimiento</h3>
          <span>{values.death_date}</span>
        </div>

        <div id="mv_date">
          <h3>Ordenación sacerdotal</h3>
          <span>{values.ordination_date}</span>
        </div>

        <div id="mv_date">
          <h3>Profesión religiosa</h3>
          <span>{values.profession_date}</span>
        </div>

        <div id="mv_date">
          <h3>Onomástica</h3>
          <span>
            {values.santoral_name}: {values.santoral_date}
          </span>
        </div>

        <h3>Biografía</h3>
        <div id="HTMLContent">
          {/* parse html */}
          <div dangerouslySetInnerHTML={{ __html: values.bioHTML }} />
        </div>

        {filesPdf.map((file, index) => (
          <BasicAccordionPdf
            titulo={file.name}
            filePdf={file.file}
          ></BasicAccordionPdf>
        ))}
      </div>
    </div>
  );
}
