import axios from 'axios';
import { useEffect, useState } from 'react'
import { CircularProgress, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {ProjectConfig} from 'Global';
import TopBar from 'elements/TopBar/TopBar';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Cookies from 'universal-cookie';
import { stringify } from 'querystring';

const cookies = new Cookies();

export default function ConstitucionesCategories (props:any) {

  //userChecks {} any
  const [userChecks, setUserChecks] = useState<any>({
    acuerdos:[
      
    ],
    constituciones:[
    ]
  });
  /*================================================
  GET USER_CHECKS // send user_id
  =================================================*/
  useEffect(() => {
    props.setLoading(true);
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'user_checks',
         //cookie user_id 
        user_id: cookies.get('user_id')

      }
    })
      .then((response) => {
        console.log("uc",response);
        setUserChecks(response.data);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
      })
  }, [])
  /*================================================*/


  
  /*================================================
  FILTERS
  =================================================*/
  const filters = [
    {type: "text", label: "Título", name: "title", value: ""},
  ]
  const [applyFilters, setApplyFilters] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>({});

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*================================================= 
  ==================================================*/


  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);

  const cookies = new Cookies();
  const user_id = cookies.get('user_id');
  const modulo  = 'Constituciones';


  
  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    props.setLoading(true);
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'constituciones_category_list',
          filters: filtersValues,
          page: page,
          user_id: user_id,
          modulo: modulo
        }
      })
        .then((response) => {
          console.log(response.data);
          setList(response.data.list)
          setTotalPages(response.data.total_pages)
          props.setLoading(false);
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList, filtersValues])
  /*=========================================================*/




  /*=========================================================
  HANDLE BOOKMARK CHECK      
  =========================================================*/

  const handleBookmark = (category_id: any) => {
    //IF ID IS IN ARRAY, REMOVE IT , IF NOT ADD IT    
    if(userChecks.constituciones.includes(category_id)){
      setUserChecks({
        ...userChecks,
        constituciones: userChecks.constituciones.filter((item: any) => item !== category_id)
      })
    }
    else{
      setUserChecks({
        ...userChecks,
        constituciones: [...userChecks.constituciones, category_id]
      })
    }
    setTriggerUpdate(true)
  }

  /*=========================================================*/


  /*=========================================================
  UPLOAD USER_CHECKS      
  =========================================================*/

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  
  useEffect(() => {
    if(triggerUpdate){
      props.setLoading(true);
      axios.post(ProjectConfig.api_url, null,
        {
          params: {
            request: 'update_user_checks',
            user_id: cookies.get('user_id'),
            userChecks: userChecks
          }
        })
          .then((response) => {
            setTriggerUpdate(false)
            props.setLoading(false);
          })
          .catch((err) => {
            console.log(err.data);
          })
    }
  }, [triggerUpdate])
  /*=========================================================*/





  return (
    <div id='Constituciones' className='module_container'>
      
      <div className="module_topBar">
        <TopBar title='Constituciones' filters={filters} setFilters={setFilters}/>
      </div>
      

      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues}/>
     

      
      <div className='module_content'>
        <div id="task_list_container" className="flex_column">

          {list.map((list_item,key) => {
            return (
              <div className='CardChecker' key={key}>    

                  <Link className='CardChecker_title' to={`/constituciones/${list_item.constituciones_category_id}`}
                  onClick={()=> {props.setLoading(true)}}>
                    <span className='CardListTitle'>{list_item.title}</span>
                  </Link>

                  <div className="bookmarkContainer">
                    {/* IF userchecks has constituciones_category_id */}
                    {userChecks.constituciones.includes(list_item.constituciones_category_id) ?
                      <BookmarkIcon className="bookmark" onClick={()=>handleBookmark(list_item.constituciones_category_id)} /> : 
                      <BookmarkBorderIcon className="bookmark" onClick={()=>handleBookmark(list_item.constituciones_category_id)} />
                    }
                  </div>
              </div>
          )})}

          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
 
        </div>
      </div>
    </div>
  );
};



