import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectConfig } from "Global";
import TopBar from "elements/TopBar/TopBar";
import Toolbar from "elements/Toolbar/Toolbar";
import DateBar from "elements/DateBar";
import App from "elements/HtmlEditor/App";
import Cookies from "universal-cookie";
import "assets/css/main.css";
import MisaAccordion from "elements/MisaAccordion";

export default function MisasViewer(props: any) {
  /*=======================================
  =======================================*/

  const [date, setDate] = useState(localStorage.getItem("date"));
  const [data, setData] = useState<any>();

  const cookies = new Cookies();
  const user_id = cookies.get("user_id");
  const modulo = "Misa del dia";

  /*=======================================
    GET DETAILS IF EDITING
  =======================================*/

  interface data_root {
    children: data_json[];
    direction: string | null;
    format: string;
    indent: number;
    type: string;
    version: number;
  }

  interface data_json {
    root: data_root;
  }

  const [lectura, setLectura] = useState<data_json | undefined>();
  const [lectio_divina, setLectio_divina] = useState<data_json>();
  const [reflexiones, setReflexiones] = useState<data_json>();

  useEffect(() => {
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "misa_dia",
          date: date,
          user_id: user_id,
          modulo: modulo,
        },
      })
      .then((response) => {
        console.log("res", response.data);
        const data = response.data;
        setData({
          ...data,
          ...(data.color.toLowerCase() === "blanco"
            ? { backgroundColor: "#FFFFFF" }
            : {}),
        });

        setLectura(data.lectura);
        setLectio_divina(data.lectio_divina);
        setReflexiones(data.reflexiones);
        props.setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [date]);
  /*=======================================
  =======================================*/

  const getLectura = () => {
    if (data && data.lectura && typeof data.lectura === "object") {
      return true;
    }
    return false;
  };

  console.log(lectura);
  return (
    <div id="SantoralView" className="module_container">
      <div className="module_topBar">
        <TopBar title="Misa del día" />
        <Toolbar />
        <DateBar onChange={setDate} />

        {data && (
          <div
            id="liturgia_header"
            style={{
              backgroundColor: data.backgroundColor,
              color: data.textColor,
            }}
          >
            <span className="ld_date">{data.fullDate}</span>
            <span className="ld_weekName">{data.weekName}</span>
            <div className="ld_colors">
              <span className="ld_color">{data.color}</span>
            </div>
          </div>
        )}
      </div>

      <div className="module_content">
        {getLectura() && (
          <div id="misaLecturaBox">
            {/* parse html */}
            {/* <App
              handleEditorChange={()=>{}}
              content={data.lectura}
              editable={true}
            /> */}

            {lectura && lectura.root && lectura.root.children.length > 0 && (
              <MisaAccordion
                titulo={"Lectura"}
                contenido={lectura}
              ></MisaAccordion>
            )}

            {lectio_divina &&
              lectio_divina.root &&
              lectio_divina.root.children.length > 0 && (
                <MisaAccordion
                  titulo={"Lectio divina"}
                  contenido={lectio_divina}
                ></MisaAccordion>
              )}
            {reflexiones &&
              reflexiones.root &&
              reflexiones.root.children.length > 0 && (
                <MisaAccordion
                  titulo={"Reflexiones"}
                  contenido={reflexiones}
                ></MisaAccordion>
              )}
          </div>
        )}
      </div>
    </div>
  );
}
