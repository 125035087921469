import React from 'react';
import { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { ProjectConfig } from 'Global';
import Cookies from 'universal-cookie';

interface CheckUlt {
  id: number;
  modelo_id: number;
  ultimo_id: number;
}

interface Props {
  titulo: string;
  contenido: string;
  isChecked: boolean;
  id: number;
  user_id: number;
  model: string;
  CheckUlt: CheckUlt;
}


const cookies = new Cookies();


export default function CheckList(props: Props) {
  const { titulo, contenido, isChecked, id, user_id, model, CheckUlt } = props;
  const [check, setcheck] = useState(isChecked);

  const [openInicio, setOpenInicio] = useState(false);
  const [openAcordion, setOpenAccordion] = useState(false);

  useEffect(() => {
    if (CheckUlt) {
      if (id == CheckUlt.modelo_id) {
        setOpenAccordion(true);
      }
      setOpenInicio(true);
    }
  }, [id, CheckUlt.modelo_id]);

  const actualizar = (id: number) => {

    // console.log()
    var estado = 0
    if(check == true){
      estado = 1
    }
    const formData = new FormData();
    formData.append("user_id", JSON.stringify(user_id));
    formData.append("check_id", JSON.stringify(id));
    formData.append("estado", JSON.stringify(estado));
    formData.append("user_id", JSON.stringify(user_id));
    formData.append("model", JSON.stringify(model));
    //AXIOS
    axios
      .post(ProjectConfig.api_url, formData, {
        params: {
          request: "check_estado",
        },
      })
      .then((response) => {

      })
      .catch((err) => {
        console.log(err.data);
      });

  }


  const accordionData = [
    {
      titulo: titulo,
      contenido: contenido,
    }
    // Agrega más elementos si es necesario
  ];

  return (
    <div>
      {openInicio && (
        <>
      {accordionData.map((item, index) => (
        <Accordion key={index} defaultExpanded={openAcordion} style={{  backgroundColor: '#FEF5E7', fontSize: '20px'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Checkbox
              size="small"
              color="primary"
              style={{ marginRight: '10px' }}
              checked={check}
              onClick={() => setcheck(!check)}
              onChange={() => actualizar(id)}
            />
            <Typography>
              <strong>{item.titulo}</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span dangerouslySetInnerHTML={{ __html: item.contenido }} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      </>
      )}
    </div>
  );
}
